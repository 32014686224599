import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAnimate } from "framer-motion";
import { setUi } from "../store";
import Icon from "./Icon";

const Button = ({ label, primary, white, color, icon, disabled, ...rest }) => {
    const [scope, animate] = useAnimate();
    const dispatch = useDispatch();

    useEffect(() => {
        const animation = async () => {
            await animate(scope.current, { scale: [1, 1.05, 1, 1.1, 1, 1.05, 1] }, { duration: 8, delay: 20, repeat: Infinity, repeatDelay: 20 });
        }

        if (primary) { animation() }
    }, [])

    return (
        <div ref={scope}>
            <div
                className={"button" + (disabled ? " disabled" : '') + (!label ? " round" : "") + (white ? " white" : (primary ? (color ? color : " brand") : ""))}
                onMouseEnter={() => dispatch(setUi(['animate_' + icon, true]))} {...rest}
            >
                {label}
                {icon && <Icon name={icon} colors={primary ? "primary:#FAFAFA" : ""}/>}
            </div>
        </div>
    )
}

export default Button;