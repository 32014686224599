import ReactGA from "react-ga4";
import { TextInput } from "grommet";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, useQualifyDeviceMutation } from "../store";

const GeoInput = ({ name }) => {
    const [qualifyDevice] = useQualifyDeviceMutation();
    const { deviceId, preferences } = useSelector(state => state.user);
    const { places } = preferences;
    const [defaultValue, setDefaultValue] = useState('');

    const dispatch = useDispatch();
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    
    const [icon, setIcon] = useState();

    useEffect(() => {
        if (places && places[name] && places[name].name) {
            setDefaultValue(places[name].name)
        }

        const center = { lat: -23.533773, lng: -46.625290 };
        const defaultBounds = {
            north: center.lat + 1,
            south: center.lat - 1,
            east: center.lng + 1,
            west: center.lng - 1,
        };

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            {
                bounds: defaultBounds,
                componentRestrictions: { country: "br" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false,
                types: ["establishment"],
            }
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const payload = {
                name: place.name,
                icon: place.icon,
                location: { type: 'Point', coordinates: [lat, lng] }
            };
            const key = 'preferences.places.' + name;
            dispatch(setUser([key, payload]));
            ReactGA.event({ category: "Interaction", action: "Added Location", label: key, value: 1 });
            qualifyDevice({ deviceId, $set: { [key]: payload } });
        setIcon(place.icon);
    });
}, [])

return (
    <TextInput
        ref={inputRef}
        placeholder="Trabalho, escola, lazer..."

        icon={icon ? <img src={icon} className="geo-icon" /> : <></>}
        reverse
        defaultValue={defaultValue}
    />
)
}

export default GeoInput;