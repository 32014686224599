import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const accountApi = createApi({
    reducerPath: "account",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            rememberDevice: builder.query({
                query: (deviceId) => {
                    return {
                        url: `/api/device/${deviceId}`,
                        method: 'GET'
                    }
                },
                providesTags: ['user']
            }),
            getCollection: builder.query({
                query: (payload) => {
                    return {
                        url: `/api/collection`,
                        method: 'POST',
                        body: payload
                    }
                },
                providesTags: ['collection']
            }),
            processResults: builder.mutation({
                query: (payload) => {
                    return {
                        url: `/api/process`,
                        method: 'POST',
                        body: payload
                    }
                },
                invalidatesTags: ['user']
            }),
            qualifyDevice: builder.mutation({
                query: (payload) => {
                    return {
                        url: `/api/device`,
                        method: 'POST',
                        body: payload
                    }
                },
                invalidatesTags: ['user', 'collection', 'results']
            }),
            invalidatesDevice: builder.mutation({
                query: (deviceId) => {
                    return {
                        url: `/api/device/${deviceId}`,
                        method: 'GET'
                    }
                },
                invalidatesTags: ['user']
            }),
        }
    }
});

export const {
    useQualifyDeviceMutation,
    useRememberDeviceQuery,
    useInvalidatesDeviceMutation,
    useGetCollectionQuery,
    useProcessResultsMutation
} = accountApi;
export default accountApi;