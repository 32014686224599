import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Icon from "./Icon";
import { useDispatch, useSelector } from "react-redux";
import { setUi } from "../store";

const Status = ({ item, icon, number, to }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div className="relative" title={"Ver " + item}>
            <motion.div
                className="status"
                onHoverStart={() => dispatch(setUi(['animate_' + icon, true]))}
                onClick={() => navigate(to)}

                layout="size"
                transition={{ ease: "anticipate" }}
                initial="initial"
                animate="initial"
                whileHover="hover"
            >
                <div className="button round">
                    <Icon name={icon} colors="primary:#111111" />
                </div>
                {number}
            </motion.div>
        </div>
    )
}

const StatusBar = () => {
    const { selection: { collection, references, results } } = useSelector(state => state.user);

    return (
        <>
            <Status item="inspirações" icon="camera" number={Array.isArray(references) ? references.length : 0} to="/minhas-referencias" />
            <Status item="imóveis" icon="home" number={(Array.isArray(results) ? results.length : 0)} to="/explorar" />
            <Status item="sua coleção" icon="heart" number={Array.isArray(collection) ? collection.length : 0} to="/minha-colecao" />
        </>
    )
}

export default StatusBar;