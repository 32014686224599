import React from "react";
import ReactGA from "react-ga4";
import Button from "../components/Button";
import { useDispatch } from "react-redux";
import { setUi } from "../store";
import { useNavigate } from "react-router-dom";
import CompositionHandpicked, { ReferencesAvatar } from "../components/CompositionHandpicked";

const AskReferences = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = () => {
        dispatch(setUi(['step', 2]));
        ReactGA.event({ category: "Onboarding", action: "Added References", label: "Avançar", value: 1 });
        navigate('/onde-morar');
    }

    return (
        <div className="questionaire">
            <div className="content">
                <div className="title-subtitle">
                    <h2 className="fix-height-3">
                        Olhe as imagens abaixo... Qual delas te faz <strong>sentir</strong> em casa?
                    </h2>
                    <p>Sua casa é um espelho de você, um reflexo do que te torna único.</p>
                </div>
                <div className="spacer"></div>
                <div className="stretch">
                    <p className="label">Selecione a imagem de cada grupo que mais te inspire</p>
                    <ReferencesAvatar />
                    <div className="buttons">
                        <Button label="Escolha para mim" onClick={handleSubmit} />
                        <Button primary label="Avançar" icon="arrow" onClick={handleSubmit} />
                    </div>
                </div>
                <div className="buttons">
                    <small className="discouraged info-message">Você poderá alterar as referências mais tarde</small>
                </div>
                <CompositionHandpicked limit={5} active={true} />
            </div>
        </div>
    )
}

export default AskReferences;