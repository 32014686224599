import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { setUser, useQualifyDeviceMutation } from "../../store";

const RegisterDeviceId = () => {
    const dispatch = useDispatch();
    const [qualifyDevice] = useQualifyDeviceMutation();

    useEffect(() => {
        const existingDeviceId = localStorage.getItem('deviceId') ? localStorage.getItem('deviceId') : "d-" + (new Date().getTime() + Math.floor(Math.random() * 1000));
        localStorage.setItem('deviceId', existingDeviceId);

        dispatch(setUser(["deviceId", existingDeviceId]));
        qualifyDevice({deviceId: existingDeviceId});
    }, [dispatch, qualifyDevice])
}

export default RegisterDeviceId;