import ReactGA from "react-ga4";
import { Avatar, TextInput } from "grommet";
import { useDispatch, useSelector } from 'react-redux';
import { addToSelection, removeFromSelection, setUi, useAddCommentMutation, useDeleteCommentMutation, useGetCommentsQuery, useGetDetailQuery, useProcessResultsMutation, useQualifyDeviceMutation } from '../store';
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import sendIcon from "../img/icons/paper-plane.png";
import HelpMessage from "./HelpMessage";
import Icon from "./Icon";
import Button from "./Button";
import { BulletList } from 'react-content-loader'
import ArrowImageControls from "./ArrowImageControls";

const Comment = ({ item, username, comment }) => {
    const [open, setOpen] = useState(false);
    const { username: myusername } = useSelector(state => state.user);
    const [deleteComment] = useDeleteCommentMutation();

    const handleDelete = () => {
        deleteComment({ item, username: myusername, comment });
    }

    return (
        <div className={"comment"}>
            <div>
                {username === myusername ?
                    <Icon name="avatar" colors="primary:#6A697F" />
                    :
                    <Avatar className="social-avatar" size="32px">
                        {username.charAt(0).toUpperCase()}
                    </Avatar>}
            </div>
            <motion.div
                className={(open ? "text" : "text summary")}
                layout="position"
                transition={{ ease: "anticipate" }}
                onClick={() => setOpen(!open)}
            >
                {username === myusername &&
                    <div className="my-comment">
                        <div className="you">
                            <small>Você</small>
                        </div>
                        <motion.div
                            layout
                            className="afford-closing"

                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 1.2 }}
                            onClick={handleDelete}
                        >
                            <Icon name="close" colors="primary:#B4B4BF" />
                        </motion.div>
                    </div>
                }
                {comment}
            </motion.div>
        </div>
    )
}

const DetailsTop = () => {
    const dispatch = useDispatch();
    const { open: { item }, keys: { enter } } = useSelector(state => state.ui);
    const { deviceId, username, selection: { collection } } = useSelector(state => state.user);
    const [qualifyDevice] = useQualifyDeviceMutation();
    const [processResults] = useProcessResultsMutation();

    useEffect(() => {
        if (enter) {
            if (collection?.includes(item)) {
                handleUnsave()
            } else {
                handleSave()
            }
            dispatch(setUi(['keys.enter', false]));
        }
    }, [enter])

    const handleSave = () => {
        dispatch(addToSelection(['collection', item]));
        ReactGA.event({ category: "Conversion", action: "Saved to collection", label: item, value: 1 });
        qualifyDevice({ deviceId, $addToSet: { 'selection.collection': item, 'selection.history.collection': item } });
        processResults({username});
    }

    const handleUnsave = () => {
        dispatch(removeFromSelection(['collection', item]));
        qualifyDevice({ deviceId, $pull: { collection: item } });
        processResults({username});
    }

    return (
        <div className="top">
            <Button
                primary={!collection?.includes(item)}
                label={collection?.includes(item) ? "Salvo" : "Salvar na coleção"}
                onClick={collection?.includes(item) ? handleUnsave : handleSave} />
        </div>
    )
}

const DetailsComments = ({ description, title }) => {
    const { open: { item } } = useSelector(state => state.ui);
    const { data, isLoading, isFetching } = useGetCommentsQuery(item);
    const ref = useRef();

    useEffect(() => {
        const timer = setTimeout(() => {
            ref?.current?.scrollIntoView({ behavior: 'smooth' });
        }, 1500);
        return () => clearTimeout(timer);
    }, [data, isFetching])

    if (isLoading) {
        return <BulletList />
    } else if (data) {
        return (
            <motion.div
                transition={{ ease: "anticipate" }}
                className="comments"
            >
                {description &&
                    <Comment item={item} username="A Imobiliária" comment={description} />
                }
                {title &&
                    <Comment item={item} username="Imobiliária" comment={title} />
                }
                {data.hasOwnProperty('comments') &&
                    data.comments.map((comment, i) => (
                        <Comment item={item} key={i} username={comment.username} comment={comment.comment} />
                    ))
                }
                <div ref={ref}></div>
            </motion.div>
        )
    }
}

const SendIcon = ({ handleClick }) => {
    const { open: { item }, comments } = useSelector(state => state.ui);
    return (
        <div className="send-icon" onClick={handleClick}>
            <div className="relative">
                <img src={sendIcon} />
                <HelpMessage condition={comments && comments[item]} message="Enter para enviar" style={{ marginTop: "-20px", marginLeft: "-20px", width: "110px", textAlign: "center" }} />
            </div>
        </div>
    )
}

const DetailsBottom = () => {
    const dispatch = useDispatch();
    const [addComment] = useAddCommentMutation();
    const { open: { item }, comments } = useSelector(state => state.ui);
    const { username } = useSelector(state => state.user);

    useEffect(() => {
        if (comments && comments.hasOwnProperty(item)) {
            dispatch(setUi(['comments.' + item, comments[item]]))
        } else {
            dispatch(setUi(['comments.' + item, undefined]))
        }
    }, [])

    const handleChange = (value) => {
        dispatch(setUi(['comments.' + item, value]));
    }

    const handleClick = (e) => {
        e.preventDefault();
        if (comments && comments[item]) {
            addComment({ item, username, comment: comments[item] });
            dispatch(setUi(['comments.' + item, undefined]));
        }
    }

    return (
        <div className="bottom">
            <p className="label">O que você achou deste imóvel?</p>
            <form onSubmit={(e) => handleClick(e)}>
                <div className="relative">
                    <TextInput
                        placeholder="Escreva aqui seu comentário"
                        icon={<></>}
                        reverse
                        onChange={(e) => handleChange(e.target.value)}
                        value={comments && comments[item]}
                    />
                    <SendIcon handleClick={handleClick} />
                </div>
            </form>
        </div>
    )
}

const DetailsBody = () => {
    const { open: { item } } = useSelector(state => state.ui);
    const { data, isLoading } = useGetDetailQuery({ item });

    if (isLoading) {
        return (
            <div className="loading">
                <lord-icon
                    colors="primary:#6A697F"
                    trigger="loop"
                    loop={true}
                    src={'/assets/interface/headline.json'}
                ></lord-icon>
                <h3>Estamos carregando os comentários</h3>
            </div>
        )
    } else if (data) {
        const first_part = []
        const last_part = []
        if (data['bedrooms']) {
            first_part.push(data['bedrooms'] + " quartos")
        }
        if (data['parking']) {
            first_part.push(data['parking'] + " vagas de garagem")
        }
        if (data['built'] && data['built'] < 10000) {
            last_part.push(data['built'].toLocaleString() + " m² de construção")
        }
        if (data['area'] && data['area'] < 10000) {
            last_part.push(data['area'].toLocaleString() + " m² de terreno")
        }

        return (
            <div className="wrapper">
                <DetailsTop />
                <div className="body">
                    <h2>No {data['neighborhood'] && data['neighborhood']}</h2>
                    <p>{[first_part.join(' e '), last_part.join(' e ')].filter(e => e).join(', distribuídos em ')}.</p>
                    <div className='spacer'></div>
                    <p className="price">{data['price'] ? "R$ " + data['price'].toLocaleString() : "Sob consulta"}</p>
                </div>
                <div className="spacer"></div>
                <div className="spacer"></div>
                <div className="spacer"></div>
                <h3>Comentários</h3>
                <DetailsComments description={data['description']} title={data['title']} />
                <DetailsBottom />
            </div>
        )
    }
}

const Carousel = ({ url }) => {
    const dispatch = useDispatch();
    const { open: { item, image_no } } = useSelector(state => state.ui);
    const { data, isLoading, isFetching } = useGetDetailQuery({ item });

    useEffect(() => {
        if (data?.img) {
            dispatch(setUi(['open.image_no', data.img.findIndex(img => img === url)]));
        }
    }, [data])

    if (isLoading || isFetching) {
        return (
            <img src={url} className="image" />
        )
    }
    if (data) {
        return (
            <img src={data.img[image_no % data.img.length]} className="image" />
        )
    }
}

const Details = ({ url }) => {
    return (
        <div className="details">
            <div className="image-details">
                <ArrowImageControls />
                <Carousel url={url} />
            </div>
            <div className="data-details">
                <DetailsBody />
            </div>
        </div>
    )
}

export default Details;