import { useGetCountQuery } from "../store";

const Count = () => {
    const { data } = useGetCountQuery();

    if (data) {
        return data.items.toLocaleString()
    } else {
        return <>...</>
    }
}

const DiscoveryBottom = () => {
    return (
        <>
            O BeHaus é exclusivo para imóveis acima de 1 milhão de reais. Nossa busca inteligente foi treinada com <Count /> casas que estão à venda neste momento. 
        </>
    )
}

export default DiscoveryBottom;