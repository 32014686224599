import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: "user",
    initialState: {
        deviceId: null,
        device: {},
        preferences: {
            history: {},
            price: null,
            places: {
                near1: null,
                near2: null,
                near3: null
            },
            urgency: null,
        },
        selection: {
            collection: [],
            references: [],
            results: [],
        },
        goals: {
            comments: 0,
            selected_references: 0,
            items_added_to_collection: 0,
            visit_scheduled: 0,
            sharing: 0
        },
        tools: {
            budgetCalculator: {}
        },
    },
    reducers: {
        setUser(state, action) {
            const keys = action.payload[0].split('.');

            if (keys.length === 1) {
                state[action.payload[0]] = action.payload[1];
            } else {
                let obj = state;

                for (let i = 0; i < keys.length - 1; i++) {
                    if (!obj.hasOwnProperty(keys[i])) {
                        obj[keys[i]] = {};
                    }
                    obj = obj[keys[i]];
                }

                obj[keys[keys.length - 1]] = action.payload[1];
            }
        },
        addToSelection(state, action) {
            const [key, value] = action.payload;
            if (state.selection[key]) {
                state.selection[key].push(value);
            } else {
                state.selection[key] = [value];
            }
        },
        removeFromSelection(state, action) {
            const [key, value] = action.payload;
            state.selection[key] = state.selection[key].filter(item => item !== value);
        },
        goalAchievement(state, action) {
            state.goals[action.payload] += 1;
        }
    }
})

export default userSlice.reducer;
export const {
    setUser, addToSelection, removeFromSelection, goalAchievement,
} = userSlice.actions;