import RegisterDeviceId from "./RegisterDeviceId";
import RememberDevice from "./RememberDevice";
import RegisterNavigator from "./RegisterNavigator";
import RegisterSessionId from "./RegisterSessionId";
import RegisterKeyControls from "./RegisterKeyControls";
import IdentifyClarity from "./IdentifyClarity";

const Tracker = () => {
    return (
        <>
            <RegisterDeviceId />
            <RememberDevice />
            <RegisterNavigator />
            <RegisterSessionId />
            <RegisterKeyControls />
            <IdentifyClarity />
        </>
    )
}

export default Tracker;