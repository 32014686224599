import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from 'react-content-loader';

import { motion } from "framer-motion";
import { setUi } from "../store";

import Loading from "./Loading";
import Icon from "./Icon";

const ImageSkeleton = () => {
    return (
        <ContentLoader
            viewBox="0 0 187 284"
            gradientDirection="top-bottom"
            className="image"
        >
            <rect x="0.5" y="0.5" width="186" height="283" rx="20" />
        </ContentLoader>
    )
}

const Image = () => {
    const [openDetails, setOpenDetails] = useState(false);
    const loading = false;

    if (loading) {
        return <ImageSkeleton />
    } else {
        return (
            <motion.div
                className="image"
                onClick={() => setOpenDetails(!openDetails)}

                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: Math.random() * 3 }}
            >
            </motion.div>
        )
    }
}

const ImageColumn = ({ order }) => {
    const odd = order % 2 === 1;
    return (
        <div className={"image-column " + (odd ? "odd" : "")}>
            <Image />
            <Image />
        </div>
    )
}

const Controls = () => {
    return (
        <div className="if-explorer">
            <div style={{ position: "fixed", top: "82px", left: "0", width: "100%", zIndex: "10000", textAlign: "center", color: "#FAFAFA", opacity: "0.7" }}>
                <h2>Casas inspiradas em você</h2>
            </div>
            <div style={{ position: "fixed", top: "calc(50% - 24px)", right: "24px", zIndex: "10000", textAlign: "center", color: "#FAFAFA", opacity: "0.7" }}>
                <Icon name="down" colors="primary:#FAFAFA" className="right-arrow"/>
            </div>
            <div style={{ position: "fixed", top: "calc(50% - 24px)", left: "24px", zIndex: "10000", textAlign: "center", color: "#FAFAFA", opacity: "0.7" }}>
                <Icon name="down" colors="primary:#FAFAFA" className="left-arrow"/>
            </div>
        </div>
    )
}

const CompositionHandpicked = () => {
    const orders = [0, 1, 2, 3, 4];
    return (
        <div className="inner-display">
            <Controls />
            {orders.map((image, i) => (<ImageColumn key={i} order={i} url={image} />))}
        </div>
    )
}

const Results = () => {
    const { loading } = useSelector(state => state.ui);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(setUi(['loading', true]));
    // }, [dispatch, setUi])

    // useEffect(() => {
    //     const timeout = window.setTimeout(() => {
    //         dispatch(setUi(['loading', false]));
    //     }, 3000);

    //     return () => window.clearTimeout(timeout);
    // }, [dispatch, setUi]);

    return (
        <>
            {loading ? <Loading /> : <CompositionHandpicked />}
        </>
    )
}

export default Results;