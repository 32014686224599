import { useEffect } from "react";
import { useSelector } from "react-redux";


const IdentifyClarity = () => {
    const { username } = useSelector(state => state.user);

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            if (username) {
                window.clarity("consent");
                window.clarity("identify", username);
                window.clarity("set", "username", username);
            }
        }, 2000);

        return () => window.clearTimeout(timeout);
    }, [username])
}

export default IdentifyClarity;