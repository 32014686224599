import Icon from "../components/Icon";
import Button from "../components/Button";
import { useEffect } from "react";
import CurrencyInput from "../components/CurrencyInput";
import { FormEdit } from "grommet-icons";
import { motion } from "framer-motion";
import HelpMessage from "../components/HelpMessage";
import { useDispatch, useSelector } from "react-redux";
import { setUser, useQualifyDeviceMutation } from "../store";
import contactViaWhatsapp from "./whatsapp";

const BudgetCalculator = () => {
    const dispatch = useDispatch();
    const [qualifyDevice] = useQualifyDeviceMutation();
    const { tools, deviceId } = useSelector(state => state.user);
    const { budgetCalculator } = tools;
    const { byProperty, price, potentialPrice, downPayment, revenue } = budgetCalculator;

    const formatCurrency = (number) => {
        return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).split(',')[0]
    }

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            qualifyDevice({
                deviceId,
                $set: {
                    'tools.budgetCalculator.price': price,
                    'tools.budgetCalculator.potential_price': potentialPrice,
                    'tools.budgetCalculator.down_payment': downPayment,
                    'tools.budgetCalculator.revenue': revenue
                }
            })
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [price, potentialPrice, downPayment, revenue]);

    const handlePrice = (price) => {
        let p = parseFloat(price);

        dispatch(setUser(['tools.budgetCalculator.price', p]));
        dispatch(setUser(['tools.budgetCalculator.potentialPrice', p]));
        dispatch(setUser(['tools.budgetCalculator.downPayment', (p * 0.1)]));
        dispatch(setUser(['tools.budgetCalculator.revenue', (p * 0.03)]));
    }

    const handleDownPayment = (gd, gr) => {
        let d = parseFloat(gd);
        let r = parseFloat(gr);
        let potential_price = Math.ceil(r * 33.333333);
        let limited_price = d * 10;

        dispatch(setUser(['tools.budgetCalculator.downPayment', d]));
        dispatch(setUser(['tools.budgetCalculator.price', Math.min(potential_price, limited_price)]));
        dispatch(setUser(['tools.budgetCalculator.potentialPrice', potential_price]));
    }

    const handleRevenue = (gd, gr) => {
        let d = parseFloat(gd);
        let r = parseFloat(gr);
        let potential_price = Math.ceil(r * 33.333333);
        let limited_price = d * 10;

        dispatch(setUser(['tools.budgetCalculator.revenue', r]));
        dispatch(setUser(['tools.budgetCalculator.price', Math.min(potential_price, limited_price)]));
        dispatch(setUser(['tools.budgetCalculator.potentialPrice', potential_price]));
    }

    useEffect(() => {
        if (!price) {
            handlePrice(1500000);
        }
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            document.getElementById('primaryFocus').focus()
        }, 1000);
        return () => clearTimeout(timer);
    }, [])

    return (
        <>
            <div className="center">
                <Icon name="calc" id="modal_icon" />
            </div>
            <h2 className="text-center">Como <strong>escolher</strong> o valor ideal?</h2>
            <p className="text-center">Existem algumas regras básicas que podem<br/> lhe ajudar a definir um orçamento</p>
            <div className="spacer"></div>
            <div className={"budget-columns " + (byProperty ? "" : "reverse")}>
                <motion.div layout className="column">
                    <Button
                        label="Pelo valor do imóvel"
                        onClick={() => dispatch(setUser(['tools.budgetCalculator.byProperty', true]))}
                    />
                </motion.div>
                <div className="middle-column">
                    <Button
                        white
                        icon="invert"
                        onClick={() => dispatch(setUser(['tools.budgetCalculator.byProperty', !byProperty]))}
                    />
                </div>
                <motion.div layout className="column">
                    <Button
                        label="Pela renda mensal"
                        onClick={() => dispatch(setUser(['tools.budgetCalculator.byProperty', false]))}
                    />
                </motion.div>
            </div>
            <div className="spacer"></div>
            <div className={"budget-columns " + (byProperty ? "" : "reverse")}>
                <div className="column">
                    <p className="label">Valor do imóvel</p>
                    <CurrencyInput
                        id="primaryFocus"
                        placeholder="R$"
                        value={price}
                        onChange={event => handlePrice(event.target.value)}
                        disabled={!byProperty}
                        icon={byProperty ? <FormEdit /> : null}
                        reverse
                    />
                </div>
                <div className="middle-column"></div>
                <div className="column">
                    <p className="label">Valor da renda mensal</p>
                    <CurrencyInput
                        placeholder="R$"
                        value={revenue}
                        onChange={event => handleRevenue(downPayment, event.target.value)}
                        disabled={byProperty}
                        icon={!byProperty ? <FormEdit /> : null}
                        reverse
                    />
                    <div className="spacer"></div>
                    <p className="label">Valor à vista ou FGTS</p>
                    <div className="relative">
                        <CurrencyInput
                            placeholder="R$"
                            value={downPayment}
                            onChange={event => handleDownPayment(event.target.value, revenue)}
                            disabled={byProperty}
                            icon={!byProperty ? <FormEdit /> : null}
                            reverse
                        />
                        <HelpMessage condition={potentialPrice > price} message={<>Sua renda permite imóveis de até {formatCurrency(revenue * 33)}, mas para isso você precisa aumentar o valor da entrada para {formatCurrency(revenue * 3.3)} (10%)</>} />
                    </div>
                </div>
            </div>
            <div className="spacer"></div>
            <Button
                primary
                label="Quero fazer uma simulação de financiamento"
                onClick={contactViaWhatsapp("Olá! Estive no site da BeHaus e gostaria de solicitar uma simulação real de financiamento. Como faço? Minha senha de atendimento é " + deviceId + ".")}
            />
            <small>Existe uma recomendação geral de que gastos com moradia não superem 30% da renda e uma estimativa de mensalidades de financiamento de 1% do valor do imóvel. Condições reais variam de acordo com o banco, o perfil do comprador e as características do imóvel.</small>
        </>
    )
}

export default BudgetCalculator;