import { createSlice } from '@reduxjs/toolkit';

const paginationSlice = createSlice({
    name: "pagination",
    initialState: {
        references: {
            data: [],
            page: 1,
            pageLimit: 16
        },
        results: {
            data: [],
            page: 1,
            pageLimit: 16
        }
    },
    reducers: {
        addData: (state, action) => {
            const [key, payload] = action.payload;
            payload.forEach((newItem) => {
                if (!state[key].data.some((existingItem) => existingItem._id === newItem._id)) {
                    state[key].data.push(newItem);
                }
            });
        },
        replaceData: (state, action) => {
            const [key, payload] = action.payload;
            state[key].data = payload;
        },
        incrementPage: (state, action) => {
            const key = action.payload;
            state[key].page += 1;
        },
        reducePage: (state, action) => {
            const key = action.payload;
            state[key].page -= 1;
        },
        setPage: (state, action) => {
            const key = action.payload[0];
            state[key].page = action.payload[1];
        },
    }
})

export default paginationSlice.reducer;
export const {
    addData, replaceData, incrementPage, reducePage, setPage
} = paginationSlice.actions;