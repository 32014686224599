import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { setUi } from '../store/slices/uiSlice';

defineElement(lottie.loadAnimation);

const Icon = ({ name, value, id, className, ...rest }) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const ui = useSelector(state => state.ui);

    const refName = id ? id : (value ? (name + "_" + value) : name);
    const animateName = 'animate_' + refName;

    useEffect(() => {
        if (ui && ui[animateName] && ui[animateName] === true && ref.current.triggerInstance && ref.current.triggerInstance.onHover) {
            ref.current.triggerInstance.onHover();
            dispatch(setUi([animateName, false]));
        }
    }, [ref.current, ui]);


    useEffect(() => {
        const timeout = window.setTimeout(() => {
            if (ui && ui[animateName] && ui[animateName] === true && ref.current.triggerInstance && ref.current.triggerInstance.onHover) {
                ref.current.triggerInstance.onHover();
                dispatch(setUi([animateName, false]));
            }
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [ref.current, ui]);

    return (
        <div className={className}>
            <lord-icon
                ref={ref}
                id={refName}
                trigger="hover"
                src={'/assets/interface/' + name + '.json'}
                {...rest}
            ></lord-icon>
        </div>
    )
}

export default Icon;