const Loading = () => {
    return (
        <div className="loading full">
            <lord-icon
                trigger="loop"
                loop={true}
                src={'/assets/interface/qrcode.json'}
            ></lord-icon>
            <h3>
                Estamos gerando recomendações para você
            </h3>
        </div>
    )
}

export default Loading;