import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { setSession, useQualifyDeviceMutation } from "../../store";

const RegisterSessionId = () => {
    const dispatch = useDispatch();
    const [qualifyDevice] = useQualifyDeviceMutation();
    const { deviceId } = useSelector((state) => state.user);

    useEffect(() => {
        function setWithExpiry(key, value, minutes) {
            const now = new Date()
            const item = {
                value: value,
                expiry: now.getTime() + (1000 * 60 * minutes),
            }
            localStorage.setItem(key, JSON.stringify(item))
        }

        function getWithExpiry(key) {
            const itemStr = localStorage.getItem(key);

            if (!itemStr) {
                return null
            }

            const item = JSON.parse(itemStr);
            const now = new Date();

            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return null
            }
            return item.value
        }

        if (deviceId) {
            const existingSessionId = getWithExpiry('sessionId') ? getWithExpiry('sessionId') : "s-" + (new Date().getTime() + Math.floor(Math.random() * 1000));
            setWithExpiry('sessionId', existingSessionId, 30);
            dispatch(setSession(['sessionId', existingSessionId]));
            qualifyDevice({ deviceId, $set: { sessions: { [existingSessionId]: new Date() } } });
        }
    }, [deviceId, dispatch, qualifyDevice])
}

export default RegisterSessionId;