import React from "react";
import ReactGA from "react-ga4";
import Button from "../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUi, setUser, useQualifyDeviceMutation } from "../store";

const AskUrgency = () => {
    const [qualifyDevice] = useQualifyDeviceMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { deviceId, preferences } = useSelector(state => state.user);
    const { urgency } = preferences;

    const options = [
        "Imediatamente",
        "Em até 3 meses",
        "Em até 6 meses",
        "Ainda não tenho data"
    ]

    const handleSubmit = (option) => {
        dispatch(setUser(['preferences.urgency', option]));

        qualifyDevice({
            deviceId,
            $set: { 'preferences.urgency': option },
            $addToSet: { 'preferences.history.urgency': option }
        });

        dispatch(setUi(['step', 4]));

        ReactGA.event({ category: "Onboarding", action: "Informed Urgency", label: option, value: 1 });

        navigate('/explorar');
    }

    return (
        <div className="questionaire">
            <div className="content">
                <div className="title-subtitle">
                    <h2 className="fix-height">
                        <strong>Quando</strong> você gostaria de se mudar?
                    </h2>
                </div>
                <div className="spacer"></div>
                {options.map((option, i) => <Button key={i} primary={urgency === option} label={option} onClick={() => handleSubmit(option)} />)}
                <div className="spacer"></div>
                <Link to="/explorar" className="discouraged">Pular essa etapa</Link>
            </div>
        </div>
    )
}

export default AskUrgency;