import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { Data, Toolbar, DataSearch, DataFilters, DataSummary } from "grommet";
import Button from "../components/Button";
import CollectionDisplay from "../components/CollectionDisplay";

import { useGetCollectionQuery, setUi } from "../store";
import EmptyState from "../components/EmptyState";
import empty from "../img/empty/room.svg";

const Collection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { username } = useSelector(state => state.user);
    const { data } = useGetCollectionQuery({ username });

    const [collectionState, setCollectionState] = useState('empty');

    const collectionStates = {
        empty: {
            title: 'Sua coleção está vazia',
            subtitle: 'Comece a explorar e adicione imóveis à sua coleção.'
        },
        few: {
            title: 'Você tem alguns imóveis na sua coleção',
            subtitle: 'Continue explorando para encontrar mais opções que atendam às suas necessidades.'
        },
        many: {
            title: 'Que coleção incrível você construiu',
            subtitle: 'Está na hora de dar vida a essa lista, explorando os imóveis que chamaram sua atenção.'
        }
    };

    useEffect(() => {
        if (data) {
            let collectionSize = data.length;
            if (collectionSize === 0) {
                setCollectionState('empty');
            } else if (collectionSize < 5) {
                setCollectionState('few');
            } else {
                setCollectionState('many');
            }
        }
    }, [data])

    const handleShare = () => {
        dispatch(setUi(['open_modal_share', true]));
        ReactGA.event({ category: "Goal", action: "Shared", label: "Compartilhar com alguém", value: 1 });
    }

    const handleVisits = () => {
        dispatch(setUi(['open_modal_share', true]));
        ReactGA.event({ category: "Goal", action: "Requested Visit", label: "Agendar visitas", value: 1 });
    }

    return (
        <div className="collection-app">
            <div className="questionaire">
                <div className="content">
                    <Data
                        data={data}
                        updateOn="change"
                        properties={{
                            neighborhood: { label: "Bairro" },
                            price: { label: "Preço" },
                            area: { label: 'Terreno', range: { min: 100, max: 5000 } },
                            built: { label: 'Área construída', range: { min: 100, max: 5000 } },
                            parking: { label: "Vagas" },
                            bedrooms: { label: "Quartos" },
                        }}
                        messages={{
                            dataFilters: {
                                clear: "Remover filtros",
                                heading: "Filtros de busca",
                                open: "Mostrar filtros"
                            },
                            dataForm: {
                                reset: "Desfazer alterações",
                                submit: "Aplicar filtros"
                            },
                            dataSearch: {
                                label: "Encontre sua casa dos sonhos"
                            },
                            dataSort: {
                                ascending: "Do menor ao maior preço",
                                by: "Ordenar por",
                                descending: "Do maior ao menor preço",
                                direction: "Ordenar por"
                            },
                            dataSummary: {
                                filtered: "{filteredTotal} casas encontradas de {total} na sua lista",
                                filteredSingle: "{filteredTotal} casa encontrada de {total} na sua lista",
                                total: "{total} casas na sua coleção"
                            },
                            dataTableColumns: {
                                open: "Escolher informações a exibir",
                                order: "Organizar colunas",
                                select: "Selecionar informações"
                            }
                        }}
                    >
                        <div className="container">
                            <div className="top">
                                <div className="title-subtitle">
                                    <h2 className="fix-height-2">
                                        {collectionStates[collectionState].title}
                                    </h2>
                                    <p>
                                        {collectionStates[collectionState].subtitle}
                                    </p>
                                </div>
                                <Toolbar>
                                    <DataFilters layer />
                                    <DataSearch />
                                </Toolbar>
                            </div>
                            <div className="toolbar stretch">
                                <div className="buttons">
                                    <Button white label="Reduzir a lista" disabled={collectionState !== 'many'} />
                                    <Button white label="Incluir mais imóveis" onClick={() => navigate("/explorar")} />
                                </div>
                                <div className="buttons">
                                    <Button label="Compartilhar com alguém" onClick={handleShare} disabled={collectionState === 'empty'} />
                                    <Button primary label="Agendar visitas" icon="arrow" onClick={handleVisits} disabled={collectionState === 'empty'} />
                                </div>
                            </div>
                            <DataSummary className="results-number" />
                        </div>
                        <CollectionDisplay />

                        {collectionState === 'empty' && <EmptyState img={empty} message="Sua coleção está vazia" action="Incluir imóveis" handleClick={() => navigate('/explorar')}/>}
                    </Data>
                </div>
            </div>
        </div>
    )
}

export default Collection;