import AnyChart from 'anychart-react';

import "./devStyles.css";
import { setUi, useGetCountQuery, useGetNodesQuery } from '../store';
import { useEffect, useState } from 'react';
import { Spinner, TextInput } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';

import Icon from "../components/Icon";

const TheWorld = () => {
    const dispatch = useDispatch();
    const { admin: { city, skip, bucket, references, similars } } = useSelector(state => state.ui);
    const { data, isLoading, isFetching } = useGetNodesQuery({ references, similars, skip, bucket, price: { $gt: 30000000 }, item: { $regex: "axpe" } });
    const type = "Imóveis"

    useEffect(() => {
        const register = (e) => {
            e = e || window.event;
            if (e.keyCode === 38) {
                dispatch(setUi(['admin.bucket', bucket + 1]));
            } else if (e.keyCode === 40) {
                dispatch(setUi(['admin.bucket', bucket - 1]));
            } else if (e.keyCode === 37) {
                dispatch(setUi(['admin.skip', skip - 1]));
            } else if (e.keyCode === 39) {
                dispatch(setUi(['admin.skip', skip + 1]));
            }
        }
        document.addEventListener('keydown', register);
    }, [skip, bucket]);

    const Control = ({ title, name, disabled }) => {
        const [value, setValue] = useState(eval(name));
        const handleLess = () => {
            if (!disabled) {
                dispatch(setUi(['admin.' + name, eval(name) - 1]));
            }
        }
        const handleMore = () => {
            if (!disabled) {
                dispatch(setUi(['admin.' + name, eval(name) + 1]));
            }
        }
        const handleSubmit = (e) => {
            if (!disabled) {
                e.preventDefault();
                dispatch(setUi(['admin.' + name, parseInt(value.match(/\d+/g))]));
            }
        }
        return (
            <div className='control'>
                <div className={'left ' + (disabled ? "disabled" : "")} onClick={handleLess} >
                    <Icon name="down" value="left" colors="primary:#FAFAFA" />
                </div>
                <div className='children text-center'>
                    <div className='label'>{title}</div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <TextInput name={name} value={value} onChange={(e) => setValue(e.target.value)} />
                    </form>
                </div>
                <div className={'right ' + (disabled ? "disabled" : "")} onClick={handleMore} >
                    <Icon name="down" value="right" colors="primary:#FAFAFA" />
                </div>
            </div>
        )
    }

    const Count = ({ name }) => {
        const { data, isFetching, isLoading } = useGetCountQuery();
        if (isLoading || isFetching) {
            return "..."
        } else if (data) {
            return <h1>{data[name].toLocaleString()}</h1>
        }
    }

    const Controls = () => {

        if (data) {
            return (
                <>
                    <div className='title'>
                        The World of <br />BeHaus
                    </div>
                    <div className='controls'>
                        <Control name="type" title="Tipo" disabled />
                        <Control name="city" title="Cidade" disabled />
                        <Control name="skip" title="Página" />
                        <Control name="bucket" title="R$ (MM)" />
                        <Control name="references" title="Clusters" />
                        <Control name="similars" title="Por cluster" />
                    </div>

                    <div className='counter'>
                        <div className='children'>
                            <Count name="items" /> itens
                        </div>
                        <div className='children'>
                            <Count name="images" /> imagens
                        </div>
                    </div>
                </>
            )
        }
    }

    if (isLoading || isFetching) {
        return (

            <div className='world-map'>
                <Controls />
                <div className='spinner'>
                    <lord-icon
                        colors="primary:#FAFAFA"
                        trigger="loop"
                        loop={true}
                        src={'http://localhost:3000/assets/interface/qrcode.json'}
                    ></lord-icon>
                    <h3>Estamos montando a nossa rede</h3>
                </div>
            </div>
        )
    } else if (data) {
        return (
            <div className='world-map'>
                <Controls />
                <AnyChart
                    type="graph"
                    data={data}
                    width="1600px"
                    height="900px"
                    fit={true}
                    id="value"
                // interactivity={false}
                />
            </div>
        )
    }
}

export default TheWorld;