import ContentLoader from 'react-content-loader';
import { useGetImagesQuery } from "../store";
import { motion } from "framer-motion";
import { useState } from 'react';

export const ImageLoader = () => {
    return (
        <div className='card'>

        </div>
        // <ContentLoader
        //     viewBox="0 0 187 284"
        //     gradientDirection="top-bottom"
        //     className="card"
        // >
        //     <rect x="0.5" y="0.5" width="186" height="283" rx="20" />
        // </ContentLoader>
    )
}

export const Image = ({ url }) => {

    return (
        <motion.div
            className="card"
            layout
            layoutRoot

            variants={{
                hidden: { opacity: 0 },
                intro: { opacity: 1, transition: { opacity: { duration: 0.3, delay: Math.random() } } },
            }}

            initial="hidden"
            animate="intro"
        >
            <div className="card-layers">
                <div className="layer">
                    <img className="image filter-mayfair" src={url} />
                </div>
            </div>
        </motion.div>
    )
}