import { useSelector } from "react-redux";
import animatedLogo from "../img/logo/animated/growing-letters.gif";
import logo from "../img/logo/static/logo_black_beta.svg";
import { Link } from "react-router-dom";

const Logo = () => {
    const { loading, screen } = useSelector(state => state.ui);

    return (
        <Link to="/">
            {loading && screen.name && screen.name === 'discovery'
                ?
                <img src={animatedLogo} height="36px" alt="logo"/>
                :
                <img src={logo} height="24px" alt="logo" />
            }
        </Link>
    )
}

export default Logo;