import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";

import { motion } from "framer-motion";

import { ImageLoader } from "./Image";
import empty from "../img/empty/diamond.svg";

import { addToSelection, incrementPage, removeFromSelection, setPage, setUi, useGetImageQuery, useGetImagesQuery, useProcessResultsMutation, useQualifyDeviceMutation } from "../store";
import EmptyState from "./EmptyState";

const EachAvatar = ({ id }) => {
    const dispatch = useDispatch();
    const { deviceId, username } = useSelector(state => state.user);
    const { data, isLoading, isFetching } = useGetImageQuery(id);
    const [qualifyDevice] = useQualifyDeviceMutation();
    const [processResults] = useProcessResultsMutation();

    const handleClick = () => {
        qualifyDevice({ deviceId, $pull: { 'selection.references': id } });
        dispatch(removeFromSelection(['references', id]));
        processResults({ username });
    }

    if (isLoading || isFetching) {
        return <img />
    } else if (data) {
        return (
            <motion.img
                src={data.url}
                onClick={handleClick}

                whileHover={{ scale: 3 }}
            />
        )
    }
}

export const ReferencesAvatar = () => {
    const { selection: { references } } = useSelector(state => state.user);
    return (
        <div className="stacked-avatars">
            {references?.map((id, i) => <EachAvatar id={id} key={i} />)}
        </div>
    )
}

const Image = ({ image }) => {
    const dispatch = useDispatch();
    const { deviceId, username, selection: { references } } = useSelector(state => state.user);
    const [qualifyDevice] = useQualifyDeviceMutation();
    const [processResults] = useProcessResultsMutation();

    const handleSave = () => {
        if (references?.includes(image._id)) {
            dispatch(removeFromSelection(['references', image._id]));
            qualifyDevice({ deviceId, $pull: { 'selection.references': image._id } });
        } else {
            dispatch(incrementPage('references'));
            dispatch(addToSelection(['references', image._id]));
            ReactGA.event({ category: "Interaction", action: "Selected Reference", label: image._id, value: 1 });
            qualifyDevice({ deviceId, $addToSet: { 'selection.references': image._id, 'selection.history.references': image._id } });
        }
        processResults({ username });
    }

    return (
        <motion.div
            className="card"
            layout
            layoutRoot

            variants={{
                hidden: { opacity: 0 },
                intro: { opacity: 1, transition: { opacity: { duration: 0.3, delay: Math.random() } } },
            }}

            initial="hidden"
            animate="intro"

            onClick={handleSave}
        >
            <div className="card-layers">
                <div className="layer">
                    <motion.img
                        className="image"
                        style={{ border: "3px solid transparent" }}
                        src={image.url}

                        variants={{
                            intro: { outline: "none" },
                            selected: { outline: "6px solid #AFAFAF" }
                        }}

                        transition={{ ease: "anticipate" }}
                        initial="initial"
                        animate={references?.includes(image._id) ? "selected" : "initial"}
                    />
                </div>
            </div>
        </motion.div>
    )
}

const CompositionHandpicked = ({ limit = 10 }) => {
    const dispatch = useDispatch();
    const { references: { page } } = useSelector(state => state.pagination);
    const { data, isLoading, isFetching } = useGetImagesQuery({ handpicked: true, limit: limit, skip: page - 1 });

    if (isLoading || isFetching) {
        return (
            <div className="inner-display" >
                <div className="image-display layout-cards" >
                    {Array.from({ length: limit }, (_, i) => i + 1).map(i => <ImageLoader key={i} />)}
                </div>
            </div>
        )
    } else if (data && Array.isArray(data) && data.length > 0) {
        return (
            <div className="inner-display">
                <div className="image-display layout-cards" >
                    {data?.map((image, i) => (<Image key={i} image={image} />))}
                </div>
            </div>
        )
    } else {
        return (
            <div className="inner-display" >
                <div className="image-display layout-cards" >
                    <EmptyState img={empty} message="Ótima seleção!" action="Recomeçar" handleClick={() => dispatch(setPage(['references', 1]))} />
                </div>
            </div>
        )
    }
}

export default CompositionHandpicked;