import React from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setUi } from "../store";
import Button from "../components/Button";
import GeoInput from "../components/GeoInput";

const AskLocation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = () => {
        dispatch(setUi(['step', 3]));
        ReactGA.event({ category: "Onboarding", action: "Advanced Location", label: "Avançar", value: 1 });
        navigate('/quando-mudar');
    }

    return (
        <div className="questionaire">
            <div className="content">
                <div className="title-subtitle">
                    <h2 className="fix-height-3">
                        Algum <strong>lugar</strong> na cidade faz parte do seu dia a dia ou da sua família?
                    </h2>
                    <p>Sua nova casa pode ser sinônimo de conforto e praticidade, conectando você aos locais que importam.</p>
                </div>
                <div className="spacer"></div>
                <p className="label">Indique seus pontos de interesse</p>
                <GeoInput name="near1" />
                <GeoInput name="near2" />
                <GeoInput name="near3" />
                <div className="spacer"></div>
                <div className="buttons">
                    <Button label="Pular" onClick={handleSubmit} />
                    <Button primary label="Avançar" icon="arrow" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default AskLocation;