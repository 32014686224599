import left from "../img/icons/carret-left.svg";
import right from "../img/icons/carret-right.svg";
import { Checkmark } from "grommet-icons";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setUi } from "../store";

const Wizard = () => {
    const { step } = useSelector(state => state.ui);
    const dispatch = useDispatch();
    const Step = ({ order }) => {
        return (
            <>
                {order <= step ? <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}><Checkmark style={{ width: "12px" }} /></motion.div> : <div className="text-weaker">{order}</div>}
            </>
        )
    }

    return (
        <>
            <Link to={-1}>
                <div className="carret">
                    <img src={left} width="24px" height="24px" alt="voltar" />
                    <div className="absolute-center"><small>Voltar</small></div>
                </div>
            </Link>
            <div className="steps">
                {[1, 2, 3, 4].map((order, i) => <Step key={i} order={order} />)}
            </div>
            <a href="#" onClick={() => dispatch(setUi(['step_submit', 1]))}>
                <div className="carret">
                    <div className="absolute-center"><small>Avançar</small></div>
                    <img src={right} width="24px" height="24px" alt="avançar" />
                </div>
            </a>
        </>
    )
}

export default Wizard;