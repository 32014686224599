import { useDispatch, useSelector } from "react-redux";
import { setUser, useProcessResultsMutation, useRememberDeviceQuery } from "../../store";
import { useEffect } from "react";

const RememberDevice = () => {
    const dispatch = useDispatch();
    const { deviceId } = useSelector(state => state.user);
    const { data } = useRememberDeviceQuery(deviceId);

    useEffect(() => {
        if (data) {
            ['preferences', 'username', 'goals', 'selection'].map(field => {
                if (data.hasOwnProperty(field)) {
                    dispatch(setUser([field, data[field]]));
                }
            })
        }
    }, [deviceId, data, dispatch])
}

export default RememberDevice;