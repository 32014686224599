import { motion } from "framer-motion";

import img01 from "../img/cover/01.jpg";
import img02 from "../img/cover/02.jpg";
import img03 from "../img/cover/03.jpg";
import img04 from "../img/cover/04.jpg";
import img05 from "../img/cover/05.jpg";
import img06 from "../img/cover/06.jpg";
import img07 from "../img/cover/07.jpg";
import img08 from "../img/cover/08.jpg";
import img09 from "../img/cover/09.jpg";
import img10 from "../img/cover/15.jpg";

export const Image = ({ url }) => {

    return (
        <motion.div
            className="card"
            layout
            layoutRoot

            variants={{
                hidden: { opacity: 0 },
                intro: { opacity: 1, transition: { opacity: { duration: 0.3, delay: Math.random() } } },
            }}

            initial="hidden"
            animate="intro"
        >
            <div className="card-layers">
                <div className="layer">
                    <img className="image" src={url} />
                </div>
            </div>
        </motion.div>
    )
}

const CompositionCover = ({ limit = 10, active = false }) => {

    return (
        <div className="inner-display">
            <div className={"image-display layout-cards" + (active ? "" : " innactive")} >
                <Image url={img01}/>
                <Image url={img02}/>
                <Image url={img04}/>
                <Image url={img05}/>
                <Image url={img06}/>
                <Image url={img10}/>
                <Image url={img09}/>
                <Image url={img08}/>
                <Image url={img07}/>
                <Image url={img03}/>
            </div>
        </div>
    )
}

export default CompositionCover;