import { createSlice } from '@reduxjs/toolkit';

const sessionSlice = createSlice({
    name: "session",
    initialState: {
        sessionId: null,
        sessionStarted: null,
        timeToNextSession: null,
        pageLimit: null,
    },
    reducers: {
        setSession(state, action) {
            const keys = action.payload[0].split('.');

            if (keys.length === 1) {
                state[action.payload[0]] = action.payload[1];
            } else {
                let obj = state;

                for (let i = 0; i < keys.length - 1; i++) {
                    if (!obj.hasOwnProperty(keys[i])) {
                        obj[keys[i]] = {};
                    }
                    obj = obj[keys[i]];
                }

                obj[keys[keys.length - 1]] = action.payload[1];
            }
        },
        addToSession(state, action) {
            const keys = action.payload[0].split('.');

            if (keys.length === 1) {
                state[action.payload[0]] = [action.payload[1]];
            } else {
                let obj = state;

                for (let i = 0; i < keys.length; i++) {
                    let group = (i === keys.length - 1) ? [] : {};
                    if (!obj.hasOwnProperty(keys[i])) {
                        obj[keys[i]] = group;
                    }
                    obj = obj[keys[i]];
                }

                obj.push(action.payload[1]);
            }
        },
        setEvent(state, action) {
            state.events.push(action.payload);
        }
    }
})

export default sessionSlice.reducer;
export const {
    setSession, addToSession, setEvent
} = sessionSlice.actions;