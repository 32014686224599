import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import accountApi from './apis/accountApi';
import adminApi from './apis/adminApi';
import resultsApi from './apis/resultsApi';
import imageApi from './apis/imageApi';
import uiSlice from './slices/uiSlice';
import userSlice from './slices/userSlice';
import sessionSlice from './slices/sessionSlice';
import paginationSlice from './slices/paginationSlice';

const store = configureStore({
    reducer: {
        ui: uiSlice,
        user: userSlice,
        session: sessionSlice,
        pagination: paginationSlice,
        [accountApi.reducerPath]: accountApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [resultsApi.reducerPath]: resultsApi.reducer,
        [imageApi.reducerPath]: imageApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(accountApi.middleware)
        .concat(adminApi.middleware)
        .concat(resultsApi.middleware)
        .concat(imageApi.middleware)
})

setupListeners(store.dispatch);

export default store;

export * from './apis/accountApi';
export * from './apis/adminApi';
export * from './apis/resultsApi';
export * from './slices/uiSlice';
export * from './slices/userSlice';
export * from './slices/sessionSlice';
export * from './slices/paginationSlice';