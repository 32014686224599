import { useDispatch, useSelector } from "react-redux";
import Icon from "./Icon";
import { setUi } from "../store";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";

const Close = ({ handleClose }) => {
    return (
        <div className="close" onClick={handleClose}>
            <Icon name="down" />
        </div>
    )
}

const Modal = ({ name, size, children, blur = "dramatic" }) => {
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const open = ui['open_modal_' + name];

    const handleClose = () => {
        dispatch(setUi(['open_modal_' + name, false]))
    }

    useEffect(() => {
        dispatch(setUi(['animate_modal_icon', true]))
    }, [open])

    return (
        <AnimatePresence mode="wait">
            {open &&
                <>
                    <div className={blur + "-blur-layer"} onClick={handleClose}>
                    </div>
                    <div className="modal-position">

                        <motion.div className="modal"
                            variants={{
                                hidden: {
                                    y: 100,
                                    opacity: 1,
                                    scale: 0
                                },
                                show: {
                                    y: 0,
                                    opacity: 1,
                                    scale: 1,
                                    transition: {
                                        type: "spring",
                                        delayChildren: 0.5,
                                        ease: "easeInOut"
                                    }
                                }
                            }}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                        >
                            <div className="relative">
                                <Close handleClose={handleClose} />
                                <div className={"inner-modal " + size + "-modal"}>
                                    {children}
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </>}
        </AnimatePresence>
    )
}


export default Modal;