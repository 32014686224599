import { motion } from "framer-motion";
import Icon from "./Icon";
import { useDispatch, useSelector } from "react-redux";
import { setUi } from "../store";
import { useEffect } from "react";

const ArrowImageControls = () => {
    const { open: { image_no }, keys: { up, down } } = useSelector(state => state.ui);
    const dispatch = useDispatch();

    useEffect(() => {
        if (up && image_no > 0) {
            dispatch(setUi(['animate_down_up', true]));
            handleUp();
        }
        dispatch(setUi(['keys.up', false]));
    }, [up, image_no])

    useEffect(() => {
        if (down) {
            dispatch(setUi(['animate_down_down', true]));
            handleDown();
        }
        dispatch(setUi(['keys.down', false]));
    }, [down])

    const handleUp = () => {
        dispatch(setUi(['open.image_no', image_no - 1]))
    }

    const handleDown = () => {
        dispatch(setUi(['open.image_no', image_no + 1]))
    }

    return (
        <>
            {image_no > 0 &&
                <motion.div
                    className="up"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 1.2 }}
                    transition={{ scale: { ease: "anticipate" } }}
                    onClick={handleUp}
                >
                    <Icon name="down" value="up" colors="primary:#FAFAFA" />
                </motion.div>
            }
            <motion.div
                className="down"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 1.2 }}
                transition={{ scale: { ease: "anticipate" } }}
                onClick={handleDown}
            >
                <Icon name="down" value="down" colors="primary:#FAFAFA" />
            </motion.div>
        </>
    )
}

export default ArrowImageControls;