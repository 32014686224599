import { useEffect } from "react";

const FixMobileScroll = () => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";
        if (document.getElementById("root")) {
            document.getElementById("root").style.overflow = "hidden";
        }
        if (document.getElementById("wrapper")) {
            document.getElementById("wrapper").style.overflow = "hidden";
        }
    }, [])
}

export default FixMobileScroll;