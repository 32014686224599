import Modal from "../components/Modal";
import Share from "../components/Share";
import BudgetCalculator from "./BudgetCalculator";

const ModalCenter = () => {
    return (
        <>
            <Modal name="budget" size="big" blur="simple">
                <BudgetCalculator />
            </Modal>
            <Modal name="share" size="medium" blur="simple">
                <Share />
            </Modal>
        </>
    )
}

export default ModalCenter;