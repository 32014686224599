import { useDispatch } from "react-redux";
import { setUi } from "../../store";
import { useEffect } from "react";

const RegisterKeyControls = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const register = (e) => {
            e = e || window.event;
            if (e.keyCode === 38) {
                dispatch(setUi(['keys.up', true]));
            } else if (e.keyCode === 40) {
                dispatch(setUi(['keys.down', true]));
            } else if (e.keyCode === 37) {
                dispatch(setUi(['keys.left', true]));
            } else if (e.keyCode === 39) {
                dispatch(setUi(['keys.right', true]));
            } else if (e.keyCode === 13) {
                dispatch(setUi(['keys.enter', true]));
            }
        }
        document.addEventListener('keydown', register);
    }, []);
}

export default RegisterKeyControls;