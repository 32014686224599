import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import App from './App';
import store from './store';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
    <Provider store={store}>
        <HttpsRedirect>
            <App />
        </HttpsRedirect>
    </Provider>
);