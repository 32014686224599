import { useSelector } from "react-redux";
import Icon from "./Icon";

const UserMenu = () => {
    const { username } = useSelector(state => state.user);

    return (
        <>
            <div className="user-menu">
                <Icon name="avatar" />
                <div className="spacer"></div>
                <span className="username" title={username ? username : ""}>{username && username} </span> 
                <span className="visiting">(visitante)</span>
            </div>
        </>
    )
}

export default UserMenu;