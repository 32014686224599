import Collection from "./Collection";
import Discovery from "./Discovery";
import AskPrice from "./AskPrice";
import AskReferences from "./AskReferences";
import AskLocation from "./AskLocation";
import AskUrgency from "./AskUrgency";
import Results from "../components/Results";
import BudgetCalculator from "../tools/BudgetCalculator";
import Empty from "../components/Empty";
import Wizard from "../components/Wizard";
import DiscoveryBottom from "../components/DiscoveryBottom";
import CompositionHandpicked from "../components/CompositionHandpicked";
import CompositionCover from "../components/CompositionCover";

const screens = {
    discovery: {
        path: "/",
        components: {
            Display: CompositionCover,
            Main: Discovery,
            Navigation: Empty,
            Bottom: DiscoveryBottom,
        },
        config: {
            layout: 2,
            menu: false,
            userMenu: false,
            wizzard: false,
            innerMain: true,
        }
    },
    onboarding_part1: {
        path: "/quanto-investir",
        components: {
            Display: CompositionCover,
            Main: AskPrice,
            Navigation: () => <Wizard currentStep={1} />,
            Bottom: Empty,
        },
        config: {
            layout: 1,
            menu: true,
            userMenu: true,
            wizzard: true,
            innerMain: true,
        }
    },
    onboarding_part2: {
        path: "/referencias",
        components: {
            Display: CompositionCover,
            Main: AskReferences,
            Navigation: () => <Wizard currentStep={2} />,
            Bottom: Empty,
        },
        config: {
            layout: 1,
            menu: true,
            userMenu: true,
            wizzard: true,
            innerMain: false,
        }
    },
    onboarding_part3: {
        path: "/onde-morar",
        components: {
            Display: CompositionCover,
            Main: AskLocation,
            Navigation: () => <Wizard currentStep={3} />,
            Bottom: Empty,
        },
        config: {
            layout: 1,
            menu: true,
            userMenu: true,
            wizzard: true,
            innerMain: true,
        }
    },
    onboarding_part4: {
        path: "/quando-mudar",
        components: {
            Display: CompositionCover,
            Main: AskUrgency,
            Navigation: () => <Wizard currentStep={4} />,
            Bottom: Empty,
        },
        config: {
            layout: 1,
            menu: true,
            userMenu: true,
            wizzard: true,
            innerMain: true,
        }
    },
    references: {
        path: "/minhas-referencias",
        components: {
            Display: CompositionCover,
            Main: AskReferences,
            Navigation: Empty,
            Bottom: Empty,
        },
        config: {
            layout: 1,
            menu: true,
            userMenu: true,
            wizzard: false,
            back: false,
            innerMain: false,
        }
    },
    collection: {
        path: "/minha-colecao",
        components: {
            Display: CompositionCover,
            Main: Collection,
            Navigation: Empty,
            Bottom: Empty,
        },
        config: {
            layout: 1,
            menu: true,
            userMenu: true,
            wizzard: false,
            back: false,
            innerMain: false,
        }
    },
    budgetTool: {
        path: "/descubra-seu-poder-de-compra",
        components: {
            Display: Results,
            Main: BudgetCalculator,
            Navigation: Empty,
            Bottom: Empty,
        },
        config: {
            layout: 1,
            menu: false,
            userMenu: false,
            wizzard: false,
            back: false,
        }
    },
}

export const landing_paths = Object.fromEntries(
    Object.entries(screens).map(([key, value]) => [key, value.path])
);

export const components = Object.fromEntries(
    Object.entries(screens).map(([key, value]) => [key, value.components])
);

export const screens_default_config = Object.fromEntries(
    Object.entries(screens).map(([key, value]) => [key, value.config])
);