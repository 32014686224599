import React, { useRef, useEffect } from 'react';
import ReactGA from "react-ga4";
import { motion } from 'framer-motion';
import "./gallery.scss";
import { useDispatch, useSelector } from 'react-redux';
import { addToSelection, removeFromSelection, replaceData, setUi, useGetImagesQuery, useProcessResultsMutation, useQualifyDeviceMutation } from '../store';
import Loading from './Loading';
import Details from "./Details";
import Button from "./Button";
import { FormCheckmark } from "grommet-icons";
import empty from "../img/empty/time.svg";

const CompositionResults = () => {
    const { open: { id } } = useSelector(state => state.ui);
    const { results: { page } } = useSelector(state => state.pagination);
    const { deviceId, username, selection: { collection, results } } = useSelector(state => state.user);
    const { data, isLoading, isFetching } = useGetImagesQuery({ id: { $in: results }, limit: 10, skip: page - 1 });
    const dispatch = useDispatch();
    const containerRefs = useRef(new Array());
    const [qualifyDevice] = useQualifyDeviceMutation();
    const [processResults] = useProcessResultsMutation();

    useEffect(() => {
        if (data) {
            dispatch(replaceData(['results', data]));
        }
    }, [data, dispatch])

    const handleClickItem = (image) => {
        if (!image || !image.hasOwnProperty('_id') || id === image._id) {
            dispatch(setUi(['open.id', null]));
            dispatch(setUi(['open.item', null]));
        } else {
            ReactGA.event({ category: "Interaction", action: "Clicked Result", label: image._id, value: 1 });
            dispatch(setUi(['open.id', image._id]));
            dispatch(setUi(['open.item', image.item]));
        }
    }

    if (isLoading || isFetching) {
        return <Loading />
    } else if (data) {
        return (
            <div className='content'>
                <div className="title text-center">
                    <h3>Resultados inspirados <strong>em você</strong></h3>
                    <p>Salve na sua coleção para comparar</p>
                </div>
                <div className="layout-cards">
                    {
                        data.map((image, i) => {

                            const handleSave = () => {
                                dispatch(addToSelection(['collection', image.item]));
                                ReactGA.event({ category: "Conversion", action: "Saved to collection", label: image.item, value: 1 });
                                qualifyDevice({ deviceId, $addToSet: { 'selection.collection': image.item, 'selection.history.collection': image.item } });
                                processResults({ username });
                            }

                            const handleUnsave = () => {
                                dispatch(removeFromSelection(['collection', image.item]));
                                qualifyDevice({ deviceId, $pull: { collection: image.item } });
                                processResults({ username });
                            }

                            return (
                                <motion.div
                                    className={id === image._id ? 'opened-card' : 'card'}
                                    key={i}
                                    layout
                                    layoutRoot

                                    variants={{
                                        hidden: { borderRadius: 'var(--border-radius)', opacity: 0 },
                                        intro: { borderRadius: 'var(--border-radius)', opacity: 1, transition: { opacity: { duration: 0.3, delay: Math.random() * 3 } } },
                                        open: { borderRadius: 'calc(2 * var(--border-radius))', opacity: 1 },
                                        hover: { scale: 1.1, transition: { ease: "anticipate" } }
                                    }}

                                    initial="hidden"
                                    animate={id === image._id ? "open" : "intro"}
                                    whileHover={id === image._id ? "" : "hover"}

                                    onClick={id === image._id ? null : () => handleClickItem(image)} // TO-DO Only when clicking in the background
                                    ref={el => containerRefs.current[image._id] = el}
                                >
                                    {id === image._id ? <Details url={image.url} /> :
                                        <div className="card-layers">
                                            <div className="layer">
                                                <motion.img
                                                    variants={{
                                                        closed: { borderRadius: 'var(--border-radius)' },
                                                        open: { borderRadius: 'calc(2 * var(--border-radius)' }
                                                    }}
                                                    initial="closed"
                                                    animate={id === image._id ? "open" : "closed"}

                                                    src={image.url}
                                                    className="image filter-mayfair"
                                                    onError={(e) => e.target.src = empty}
                                                />
                                            </div>
                                            <motion.div
                                                className="layer overlay"
                                                variants={{
                                                    "rest": { background: "linear-gradient(180deg, transparent 35%, rgba(0, 0, 0, .7))" },
                                                    "hover": { background: "linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, .8))" }
                                                }}
                                                initial="rest"
                                                whileHover="hover"
                                            >
                                                <motion.div
                                                    className="layer overlay"
                                                    variants={{
                                                        "rest": { opacity: 0 },
                                                        "hover": { opacity: 1 }
                                                    }}
                                                    initial="rest"
                                                    whileHover="hover"
                                                >
                                                    <div className="controls">
                                                        <Button
                                                            primary={!collection?.includes(image.item)}
                                                            white={collection?.includes(image.item)}
                                                            label={collection?.includes(image.item) ? "Salvo" : "Salvar"}
                                                            onClick={collection?.includes(image.item) ? handleUnsave : handleSave} />
                                                    </div>
                                                    <div className="action">
                                                        {/* <h3>Ver imóvel</h3> */}
                                                    </div>
                                                    <div className="info">
                                                        <FormCheckmark color='#B4B4BF' />
                                                        <div className="price">
                                                            {image['price'] ? "R$ " + image['price'].toLocaleString() : "Sob consulta"}
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            </motion.div>
                                        </div>
                                    }
                                </motion.div>
                            )
                        }
                        )
                    }
                    <motion.div
                        className={"dim-layer " + (id ? "allow-exit" : "")}
                        onClick={() => handleClickItem(null)}
                        animate={{ opacity: id ? .3 : 0 }}
                    />
                </div>
            </div>
        )
    }
}

export default CompositionResults;