import Button from "./Button";

const EmptyState = ({ img, message, action, handleClick }) => {
    return (
        <div className="empty-state">
            <img src={img} />
            <h3 className="text-weaker">{message}</h3>
            <br />
            <Button white label={action} onClick={handleClick} />
        </div>
    )
}

export default EmptyState;