import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Select } from "grommet";

import { setUi, setUser, useQualifyDeviceMutation } from "../store";
import Button from "../components/Button";
import HelpMessage from "../components/HelpMessage";

const AskPrice = () => {
    const navigate = useNavigate();
    const [qualifyDevice] = useQualifyDeviceMutation();
    const { deviceId, preferences } = useSelector(state => state.user);
    const { step_submit } = useSelector(state => state.ui);
    const { price } = preferences;

    const dispatch = useDispatch();

    const handleSubmit = () => {
        dispatch(setUi(['step', 1]));
        ReactGA.event({ category: "Onboarding", action: "Informed Price", label: "Avançar", value: 1 });
        navigate('/referencias');
    }

    useEffect(() => {
        if (step_submit === 1) {
            if (price && price > 1) {
                handleSubmit();
            }
            dispatch(setUi(['step_submit', null]));
        }
    }, [step_submit])

    const handleChange = (nextValue) => {
        dispatch(setUser(['preferences.price', nextValue]));
        qualifyDevice({
            deviceId,
            $set: { 'preferences.price': nextValue },
            $addToSet: { 'preferences.history.price': nextValue }
        });
    }

    const options = [
        { label: 'Até R$ 1 milhão', value: 1 },
        { label: 'Até R$ 2 milhões', value: 2 },
        { label: 'Até R$ 3 milhões', value: 3 },
        { label: 'Até R$ 4 milhões', value: 4 },
        { label: 'Até R$ 5 milhões', value: 5 },
        { label: 'Até R$ 6 milhões', value: 6 },
        { label: 'Até R$ 7 milhões', value: 7 },
        { label: 'Até R$ 8 milhões', value: 8 },
        { label: 'Até R$ 9 milhões', value: 9 },
        { label: 'Até R$ 10 milhões', value: 10 },
        { label: 'Acima de R$ 10 milhões', value: 20 },
        { label: 'Ainda não defini, estou só sonhando', value: "dreamer" },

    ]

    return (
        <div className="questionaire">
            <div className="content">
                <div className="title-subtitle">
                    <h2 className="fix-height-3">
                        Vamos começar definindo o <strong>valor máximo</strong> para o seu próximo lar...
                    </h2>
                </div>
                <div className="spacer"></div>
                <p className="label">Selecione uma faixa de valor aproximada</p>
                <div className="stretch">
                    <div className="relative">
                        <Select
                            options={options}
                            labelKey="label"
                            valueKey="value"
                            value={price}
                            onChange={({ value: nextValue }) => handleChange(nextValue.value)}
                            placeholder="Selecione uma opção"
                            autoFocus
                            width="500px"
                        />
                        <HelpMessage condition={price === 1} message={<>O Behaus é exclusivo para imóveis acima de R$ 1 milhão. <br /><b>Quer escolher outro valor?</b></>} />
                    </div>
                </div>
                <small className="discouraged info-message">Você poderá refinar este valor mais tarde</small>
                <div className="spacer"></div>
                <div className="spacer"></div>
                <div className="buttons">
                    <Button label="Como escolher o valor ideal?" onClick={() => dispatch(setUi(['open_modal_budget', true]))} />
                    <Button primary label="Avançar" icon="arrow" onClick={handleSubmit} disabled={!price || price === 1} />
                </div>
            </div>
        </div>
    )
}

export default AskPrice;