import { useSelector } from 'react-redux';
import {
    EmailShareButton, EmailIcon,
    FacebookShareButton, FacebookIcon,
    FacebookMessengerShareButton, FacebookMessengerIcon,
    TelegramShareButton, TelegramIcon,
    WhatsappShareButton, WhatsappIcon,
    LinkedinShareButton, LinkedinIcon,
} from 'react-share';
import Icon from './Icon';
import { TextInput } from 'grommet';
import Button from './Button';
import { useState } from 'react';
import { motion } from 'framer-motion';

const Pop = ({ children }) => {
    return (

        <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1.2 }}
            transition={{ ease: "anticipate" }}
        >
            {children}
        </motion.div>
    )
}

const SocialMedia = () => {
    const { username } = useSelector(state => state.user);
    const house_collection_id = encodeURI(username);

    return (
        <div className="help-message" style={{ width: "300px" }}>
            <div className='buttons'>
                <Pop>
                    <FacebookShareButton
                        url={`https://behaus.co/${house_collection_id}`}
                        quote={`Estou pensando em algumas destas casas na BeHaus. Pode me dar sua opinião?`}
                        hashtag="#BeHaus"
                    >
                        <FacebookIcon size={40} round />
                    </FacebookShareButton>
                </Pop>
                <Pop>
                    <FacebookMessengerShareButton
                        url={`https://behaus.co/${house_collection_id}`}
                        appId="275919551837230"
                        redirectUri="https://behaus.co/"
                        to="userId"
                    >
                        <FacebookMessengerIcon size={40} round />
                    </FacebookMessengerShareButton>
                </Pop>
                <Pop>
                    <TelegramShareButton
                        url={`https://behaus.co/${house_collection_id}`}
                        title={`Estou olhando algumas casas na BeHaus. O que você acha destas?`}
                    >
                        <TelegramIcon size={40} round />
                    </TelegramShareButton>
                </Pop>
                <Pop>
                    <WhatsappShareButton
                        url={`https://behaus.co/${house_collection_id}`}
                        title={`Encontrei estas casas na BeHaus. Pode me ajudar a escolher?`}
                    >
                        <WhatsappIcon size={40} round />
                    </WhatsappShareButton>
                </Pop>
                <Pop>
                    <LinkedinShareButton
                        url={`https://behaus.co/${house_collection_id}`}
                        title={`Estou considerando estas casas na BeHaus. Adoraria sua opinião.`}
                        summary="Ajude-me a escolher a casa perfeita na BeHaus."
                        source="BeHaus"
                    >
                        <LinkedinIcon size={40} round />
                    </LinkedinShareButton>
                </Pop>
                <Pop>
                    <EmailShareButton
                        url={`https://behaus.co/${house_collection_id}`}
                        subject={`Sua opinião sobre estas casas na BeHaus`}
                        body={`Olá!

Estou considerando algumas casas e gostaria da sua opinião. Você pode dar uma olhada e me dizer o que pensa?

Aqui está o link: https://behaus.co/${house_collection_id}

Obrigado!`}>
                        <EmailIcon size={40} round />
                    </EmailShareButton>
                </Pop>
            </div>
        </div>
    )
}

const Share = () => {
    const { username } = useSelector(state => state.user);
    const house_collection_id = username.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-').toLowerCase();
    const [open, setOpen] = useState(false);

    return (
        <div className="text-center">
            <Icon name="human" id="modal_icon" />
            <h2>Compartilhe sua coleção</h2>
            <div className="spacer"></div>

            <p>72% dos compradores trilham o<br /> caminho com alguém especial</p>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>

            <div className="buttons" >
                <TextInput value={"https://behaus.ai/" + house_collection_id} />
                <div className='relative'>
                    <Button
                        icon="share"
                        label="Compartilhar"
                        onMouseEnter={() => setOpen(true)}
                        onClick={() => setOpen(!open)}
                    />
                    {open && <SocialMedia />}
                </div>
                <Button primary icon="copy" label="Copiar link" />
            </div>
        </div>
    )
}

export default Share;