import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const imageApi = createApi({
    reducerPath: "image",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            getImage: builder.query({
                query: (payload) => {
                    return {
                        url: `/api/image`,
                        method: 'POST',
                        body: payload
                    }
                },
            }),
        }
    }
});

export const {
    useGetImageQuery
} = imageApi;
export default imageApi;