import Logo from "../components/Logo";
import CompositionResults from "../components/CompositionResults";
import Menu from "../components/Menu";
import UserMenu from "../components/UserMenu";
import ArrowControls from "../components/ArrowControls";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setUi } from "../store";
import { Button } from "grommet";
import { useNavigate } from "react-router-dom";
import StatusBar from "../components/StatusBar";

const Explorer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setUi(['open.id', null]));
        dispatch(setUi(['open.item', null]));
    }, [])

    return (
        <div className='explorer-app'>
            <div className='header'>
                <div className="left-menu">
                    <Logo />
                    <Button label="Recomeçar" size="xsmall" onClick={() => navigate('/')}/>
                </div>
                <div className="menu">
                    <StatusBar />
                </div>
                <div className="right-menu">
                    <UserMenu />
                </div>
            </div>
            <div className='main'>
                <CompositionResults />
            </div>
            <ArrowControls />
        </div>
    )
}

export default Explorer;