import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, useQualifyDeviceMutation } from "../../store";

const RegisterNavigator = () => {
    const dispatch = useDispatch();
    const [qualifyDevice] = useQualifyDeviceMutation();
    const { deviceId } = useSelector((state) => state.user);
    const { sessionId } = useSelector((state) => state.session);

    useEffect(() => {
        if (deviceId) {
            // Navigator
            const usefulNavigatorProperties = [
                'appCodeName',
                'appName',
                'appVersion',
                'platform',
                'userAgent',
                'cookieEnabled',
                'language',
                'doNotTrack',
                'hardwareConcurrency',
                'maxTouchPoints'
            ];
            const navigatorInfo = {};
            usefulNavigatorProperties.forEach((property) => {
                if (property in window.navigator) {
                    navigatorInfo[property] = window.navigator[property];
                }
            });

            // Screen
            const screen = {
                width: window.innerWidth,
                height: window.innerHeight
            }

            // Timezone
            const timeZoneCityToCountry = {
                "Andorra": "Andorra",
                "Dubai": "United Arab Emirates",
                "Kabul": "Afghanistan",
                "Tirane": "Albania",
                "Yerevan": "Armenia",
                "Casey": "Antarctica",
                "Davis": "Antarctica",
                "Mawson": "Antarctica",
                "Palmer": "Antarctica",
                "Rothera": "Antarctica",
                "Troll": "Antarctica",
                "Buenos_Aires": "Argentina",
                "Cordoba": "Argentina",
                "Salta": "Argentina",
                "Jujuy": "Argentina",
                "Tucuman": "Argentina",
                "Catamarca": "Argentina",
                "La_Rioja": "Argentina",
                "San_Juan": "Argentina",
                "Mendoza": "Argentina",
                "San_Luis": "Argentina",
                "Rio_Gallegos": "Argentina",
                "Ushuaia": "Argentina",
                "Pago_Pago": "Samoa (American)",
                "Vienna": "Austria",
                "Lord_Howe": "Australia",
                "Macquarie": "Australia",
                "Hobart": "Australia",
                "Melbourne": "Australia",
                "Sydney": "Australia",
                "Broken_Hill": "Australia",
                "Brisbane": "Australia",
                "Lindeman": "Australia",
                "Adelaide": "Australia",
                "Darwin": "Australia",
                "Perth": "Australia",
                "Eucla": "Australia",
                "Baku": "Azerbaijan",
                "Barbados": "Barbados",
                "Dhaka": "Bangladesh",
                "Brussels": "Belgium",
                "Sofia": "Bulgaria",
                "Bermuda": "Bermuda",
                "La_Paz": "Bolivia",
                "Noronha": "Brazil",
                "Belem": "Brazil",
                "Fortaleza": "Brazil",
                "Recife": "Brazil",
                "Araguaina": "Brazil",
                "Maceio": "Brazil",
                "Bahia": "Brazil",
                "Sao_Paulo": "Brazil",
                "Campo_Grande": "Brazil",
                "Cuiaba": "Brazil",
                "Santarem": "Brazil",
                "Porto_Velho": "Brazil",
                "Boa_Vista": "Brazil",
                "Manaus": "Brazil",
                "Eirunepe": "Brazil",
                "Rio_Branco": "Brazil",
                "Thimphu": "Bhutan",
                "Minsk": "Belarus",
                "Belize": "Belize",
                "St_Johns": "Canada",
                "Halifax": "Canada",
                "Glace_Bay": "Canada",
                "Moncton": "Canada",
                "Goose_Bay": "Canada",
                "Toronto": "Canada",
                "Iqaluit": "Canada",
                "Winnipeg": "Canada",
                "Resolute": "Canada",
                "Rankin_Inlet": "Canada",
                "Regina": "Canada",
                "Swift_Current": "Canada",
                "Edmonton": "Canada",
                "Cambridge_Bay": "Canada",
                "Inuvik": "Canada",
                "Dawson_Creek": "Canada",
                "Fort_Nelson": "Canada",
                "Whitehorse": "Canada",
                "Dawson": "Canada",
                "Vancouver": "Canada",
                "Zurich": "Switzerland",
                "Abidjan": "Côte d'Ivoire",
                "Rarotonga": "Cook Islands",
                "Santiago": "Chile",
                "Punta_Arenas": "Chile",
                "Easter": "Chile",
                "Shanghai": "China",
                "Urumqi": "China",
                "Bogota": "Colombia",
                "Costa_Rica": "Costa Rica",
                "Havana": "Cuba",
                "Cape_Verde": "Cape Verde",
                "Nicosia": "Cyprus",
                "Famagusta": "Cyprus",
                "Prague": "Czech Republic",
                "Berlin": "Germany",
                "Santo_Domingo": "Dominican Republic",
                "Algiers": "Algeria",
                "Guayaquil": "Ecuador",
                "Galapagos": "Ecuador",
                "Tallinn": "Estonia",
                "Cairo": "Egypt",
                "El_Aaiun": "Western Sahara",
                "Madrid": "Spain",
                "Ceuta": "Spain",
                "Canary": "Spain",
                "Helsinki": "Finland",
                "Fiji": "Fiji",
                "Stanley": "Falkland Islands",
                "Kosrae": "Micronesia",
                "Faroe": "Faroe Islands",
                "Paris": "France",
                "London": "Britain (UK)",
                "Tbilisi": "Georgia",
                "Cayenne": "French Guiana",
                "Gibraltar": "Gibraltar",
                "Nuuk": "Greenland",
                "Danmarkshavn": "Greenland",
                "Scoresbysund": "Greenland",
                "Thule": "Greenland",
                "Athens": "Greece",
                "South_Georgia": "South Georgia & the South Sandwich Islands",
                "Guatemala": "Guatemala",
                "Guam": "Guam",
                "Bissau": "Guinea-Bissau",
                "Guyana": "Guyana",
                "Hong_Kong": "Hong Kong",
                "Tegucigalpa": "Honduras",
                "Port-au-Prince": "Haiti",
                "Budapest": "Hungary",
                "Jakarta": "Indonesia",
                "Pontianak": "Indonesia",
                "Makassar": "Indonesia",
                "Jayapura": "Indonesia",
                "Dublin": "Ireland",
                "Jerusalem": "Israel",
                "Kolkata": "India",
                "Chagos": "British Indian Ocean Territory",
                "Baghdad": "Iraq",
                "Tehran": "Iran",
                "Rome": "Italy",
                "Jamaica": "Jamaica",
                "Amman": "Jordan",
                "Tokyo": "Japan",
                "Nairobi": "Kenya",
                "Bishkek": "Kyrgyzstan",
                "Tarawa": "Kiribati",
                "Kanton": "Kiribati",
                "Kiritimati": "Kiribati",
                "Pyongyang": "Korea (North)",
                "Seoul": "Korea (South)",
                "Almaty": "Kazakhstan",
                "Qyzylorda": "Kazakhstan",
                "Qostanay": "Kazakhstan",
                "Aqtobe": "Kazakhstan",
                "Aqtau": "Kazakhstan",
                "Atyrau": "Kazakhstan",
                "Oral": "Kazakhstan",
                "Beirut": "Lebanon",
                "Colombo": "Sri Lanka",
                "Monrovia": "Liberia",
                "Vilnius": "Lithuania",
                "Riga": "Latvia",
                "Tripoli": "Libya",
                "Casablanca": "Morocco",
                "Chisinau": "Moldova",
                "Kwajalein": "Marshall Islands",
                "Yangon": "Myanmar (Burma)",
                "Ulaanbaatar": "Mongolia",
                "Hovd": "Mongolia",
                "Choibalsan": "Mongolia",
                "Macau": "Macau",
                "Martinique": "Martinique",
                "Malta": "Malta",
                "Mauritius": "Mauritius",
                "Maldives": "Maldives",
                "Mexico_City": "Mexico",
                "Cancun": "Mexico",
                "Merida": "Mexico",
                "Monterrey": "Mexico",
                "Matamoros": "Mexico",
                "Chihuahua": "Mexico",
                "Ciudad_Juarez": "Mexico",
                "Ojinaga": "Mexico",
                "Mazatlan": "Mexico",
                "Bahia_Banderas": "Mexico",
                "Hermosillo": "Mexico",
                "Tijuana": "Mexico",
                "Kuching": "Malaysia",
                "Maputo": "Mozambique",
                "Windhoek": "Namibia",
                "Noumea": "New Caledonia",
                "Norfolk": "Norfolk Island",
                "Lagos": "Nigeria",
                "Managua": "Nicaragua",
                "Kathmandu": "Nepal",
                "Nauru": "Nauru",
                "Niue": "Niue",
                "Auckland": "New Zealand",
                "Chatham": "New Zealand",
                "Panama": "Panama",
                "Lima": "Peru",
                "Tahiti": "French Polynesia",
                "Marquesas": "French Polynesia",
                "Gambier": "French Polynesia",
                "Port_Moresby": "Papua New Guinea",
                "Bougainville": "Papua New Guinea",
                "Manila": "Philippines",
                "Karachi": "Pakistan",
                "Warsaw": "Poland",
                "Miquelon": "St Pierre & Miquelon",
                "Pitcairn": "Pitcairn",
                "Puerto_Rico": "Puerto Rico",
                "Gaza": "Palestine",
                "Hebron": "Palestine",
                "Lisbon": "Portugal",
                "Madeira": "Portugal",
                "Azores": "Portugal",
                "Palau": "Palau",
                "Asuncion": "Paraguay",
                "Qatar": "Qatar",
                "Bucharest": "Romania",
                "Belgrade": "Serbia",
                "Kaliningrad": "Russia",
                "Moscow": "Russia",
                "Simferopol": "Russia",
                "Kirov": "Russia",
                "Volgograd": "Russia",
                "Astrakhan": "Russia",
                "Saratov": "Russia",
                "Ulyanovsk": "Russia",
                "Samara": "Russia",
                "Yekaterinburg": "Russia",
                "Omsk": "Russia",
                "Novosibirsk": "Russia",
                "Barnaul": "Russia",
                "Tomsk": "Russia",
                "Novokuznetsk": "Russia",
                "Krasnoyarsk": "Russia",
                "Irkutsk": "Russia",
                "Chita": "Russia",
                "Yakutsk": "Russia",
                "Khandyga": "Russia",
                "Vladivostok": "Russia",
                "Ust-Nera": "Russia",
                "Magadan": "Russia",
                "Sakhalin": "Russia",
                "Srednekolymsk": "Russia",
                "Kamchatka": "Russia",
                "Anadyr": "Russia",
                "Riyadh": "Saudi Arabia",
                "Guadalcanal": "Solomon Islands",
                "Khartoum": "Sudan",
                "Singapore": "Singapore",
                "Paramaribo": "Suriname",
                "Juba": "South Sudan",
                "Sao_Tome": "Sao Tome & Principe",
                "El_Salvador": "El Salvador",
                "Damascus": "Syria",
                "Grand_Turk": "Turks & Caicos Is",
                "Ndjamena": "Chad",
                "Bangkok": "Thailand",
                "Dushanbe": "Tajikistan",
                "Fakaofo": "Tokelau",
                "Dili": "East Timor",
                "Ashgabat": "Turkmenistan",
                "Tunis": "Tunisia",
                "Tongatapu": "Tonga",
                "Istanbul": "Turkey",
                "Taipei": "Taiwan",
                "Kyiv": "Ukraine",
                "New_York": "United States",
                "Detroit": "United States",
                "Louisville": "United States",
                "Monticello": "United States",
                "Indianapolis": "United States",
                "Vincennes": "United States",
                "Winamac": "United States",
                "Marengo": "United States",
                "Petersburg": "United States",
                "Vevay": "United States",
                "Chicago": "United States",
                "Tell_City": "United States",
                "Knox": "United States",
                "Menominee": "United States",
                "Center": "United States",
                "New_Salem": "United States",
                "Beulah": "United States",
                "Denver": "United States",
                "Boise": "United States",
                "Phoenix": "United States",
                "Los_Angeles": "United States",
                "Anchorage": "United States",
                "Juneau": "United States",
                "Sitka": "United States",
                "Metlakatla": "United States",
                "Yakutat": "United States",
                "Nome": "United States",
                "Adak": "United States",
                "Honolulu": "United States",
                "Montevideo": "Uruguay",
                "Samarkand": "Uzbekistan",
                "Tashkent": "Uzbekistan",
                "Caracas": "Venezuela",
                "Ho_Chi_Minh": "Vietnam",
                "Efate": "Vanuatu",
                "Apia": "Samoa (western)",
                "Johannesburg": "South Africa",
                "Antigua": "Antigua & Barbuda",
                "Anguilla": "Anguilla",
                "Luanda": "Angola",
                "McMurdo": "Antarctica",
                "DumontDUrville": "Antarctica",
                "Syowa": "Antarctica",
                "Vostok": "Antarctica",
                "Aruba": "Aruba",
                "Mariehamn": "Åland Islands",
                "Sarajevo": "Bosnia & Herzegovina",
                "Ouagadougou": "Burkina Faso",
                "Bahrain": "Bahrain",
                "Bujumbura": "Burundi",
                "Porto-Novo": "Benin",
                "St_Barthelemy": "St Barthelemy",
                "Brunei": "Brunei",
                "Kralendijk": "Caribbean NL",
                "Nassau": "Bahamas",
                "Gaborone": "Botswana",
                "Blanc-Sablon": "Canada",
                "Atikokan": "Canada",
                "Creston": "Canada",
                "Cocos": "Cocos (Keeling) Islands",
                "Kinshasa": "Congo (Dem. Rep.)",
                "Lubumbashi": "Congo (Dem. Rep.)",
                "Bangui": "Central African Rep.",
                "Brazzaville": "Congo (Rep.)",
                "Douala": "Cameroon",
                "Curacao": "Curaçao",
                "Christmas": "Christmas Island",
                "Busingen": "Germany",
                "Djibouti": "Djibouti",
                "Copenhagen": "Denmark",
                "Dominica": "Dominica",
                "Asmara": "Eritrea",
                "Addis_Ababa": "Ethiopia",
                "Chuuk": "Micronesia",
                "Pohnpei": "Micronesia",
                "Libreville": "Gabon",
                "Grenada": "Grenada",
                "Guernsey": "Guernsey",
                "Accra": "Ghana",
                "Banjul": "Gambia",
                "Conakry": "Guinea",
                "Guadeloupe": "Guadeloupe",
                "Malabo": "Equatorial Guinea",
                "Zagreb": "Croatia",
                "Isle_of_Man": "Isle of Man",
                "Reykjavik": "Iceland",
                "Jersey": "Jersey",
                "Phnom_Penh": "Cambodia",
                "Comoro": "Comoros",
                "St_Kitts": "St Kitts & Nevis",
                "Kuwait": "Kuwait",
                "Cayman": "Cayman Islands",
                "Vientiane": "Laos",
                "St_Lucia": "St Lucia",
                "Vaduz": "Liechtenstein",
                "Maseru": "Lesotho",
                "Luxembourg": "Luxembourg",
                "Monaco": "Monaco",
                "Podgorica": "Montenegro",
                "Marigot": "St Martin (French)",
                "Antananarivo": "Madagascar",
                "Majuro": "Marshall Islands",
                "Skopje": "North Macedonia",
                "Bamako": "Mali",
                "Saipan": "Northern Mariana Islands",
                "Nouakchott": "Mauritania",
                "Montserrat": "Montserrat",
                "Blantyre": "Malawi",
                "Kuala_Lumpur": "Malaysia",
                "Niamey": "Niger",
                "Amsterdam": "Netherlands",
                "Oslo": "Norway",
                "Muscat": "Oman",
                "Reunion": "Réunion",
                "Kigali": "Rwanda",
                "Mahe": "Seychelles",
                "Stockholm": "Sweden",
                "St_Helena": "St Helena",
                "Ljubljana": "Slovenia",
                "Longyearbyen": "Svalbard & Jan Mayen",
                "Bratislava": "Slovakia",
                "Freetown": "Sierra Leone",
                "San_Marino": "San Marino",
                "Dakar": "Senegal",
                "Mogadishu": "Somalia",
                "Lower_Princes": "St Maarten (Dutch)",
                "Mbabane": "Eswatini (Swaziland)",
                "Kerguelen": "French S. Terr.",
                "Lome": "Togo",
                "Port_of_Spain": "Trinidad & Tobago",
                "Funafuti": "Tuvalu",
                "Dar_es_Salaam": "Tanzania",
                "Kampala": "Uganda",
                "Midway": "US minor outlying islands",
                "Wake": "US minor outlying islands",
                "Vatican": "Vatican City",
                "St_Vincent": "St Vincent",
                "Tortola": "Virgin Islands (UK)",
                "St_Thomas": "Virgin Islands (US)",
                "Wallis": "Wallis & Futuna",
                "Aden": "Yemen",
                "Mayotte": "Mayotte",
                "Lusaka": "Zambia",
                "Harare": "Zimbabwe"
            }
            let userCity = null;
            let userCountry = null;
            let userTimeZone = null;
            if (Intl) {
                userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                var tzArr = userTimeZone.split("/");
                userCity = tzArr[tzArr.length - 1];
                userCountry = timeZoneCityToCountry[userCity];
            }
            const timezone = { timezone: userTimeZone, city: userCity, country: userCountry }

            // Save Navigator, Screen, Timezone
            dispatch(setUser(['device.navigator', { screen, timezone, ...navigatorInfo }]));
            qualifyDevice({ deviceId, $set: { "navigator": { screen, timezone, ...navigatorInfo } } });

            // IP location
            fetch('https://api.ipify.org?format=json')
                .then(response => response.json())
                .then(data => {
                    const ip = data.ip;
                    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=a216d9dfd60a4208acc453de74b1fac5&ip=${ip}`)
                        .then(response => response.json())
                        .then(data => {
                            dispatch(setUser(['device.location.ip', data]));
                            dispatch(setUser(['ip', data.ip]));
                            qualifyDevice({ deviceId, $set: { "location.ip": data } });
                        })
                })

            // Authorized location
            navigator.geolocation.getCurrentPosition((position) => {
                const geolocation = { latitude: position.coords.latitude, longitude: position.coords.longitude, accuracy: position.coords.accuracy }
                dispatch(setUser(['device.location.authorized', geolocation]));
                qualifyDevice({ deviceId, $set: { "location.authorized": geolocation } });
            });

        }
    }, [deviceId, dispatch]);
}

export default RegisterNavigator;