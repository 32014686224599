import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import Icon from "./Icon";

import { incrementPage, reducePage, setUi } from "../store";

const Left = () => {
    const dispatch = useDispatch();
    const { results: { data, page } } = useSelector(state => state.pagination);
    const { open: { id }, keys: { left } } = useSelector(state => state.ui);
    const [mode, setMode] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        setMode(false);
        setIndex(0);
        if (data) {
            const firstPage = 1;
            const firstImage = 0;
            if (!!id) {
                const id_index = data.findIndex(image => image._id && image._id === id);
                setIndex(id_index);
                if (id_index > firstImage) {
                    setMode('id');
                } else if (page > firstPage) {
                    setMode('page');
                }
            } else if (page > firstPage) {
                setMode('page');
            }
        }
    }, [data, page, id])

    useEffect(() => {
        if (left) {
            dispatch(setUi(['animate_down_left', true]));
            handleClick();
            dispatch(setUi(['keys.left', false]));
        }
    }, [left])

    const handleClick = () => {
        if (mode === 'id') {
            dispatch(setUi(['open.id', data[index - 1]._id]));
            dispatch(setUi(['open.item', data[index - 1].item]));
        } else if (mode === 'page') {
            dispatch(reducePage('results'));
            dispatch(setUi(['open.id', null]));
            dispatch(setUi(['open.item', null]));
        }
    }

    return (
        <motion.div
            className="left"
            variants={{
                "hidden": { opacity: 0 },
                "show": { opacity: 0.4 },
                "hover": { scale: 1.1 },
                "tap": { scale: 1.2 }
            }}
            initial="hidden"
            animate={mode ? "show" : "hidden"}
            whileHover="hover"
            whileTap="tap"
            transition={{ opacity: { ease: "easeIn" }, scale: { ease: "anticipate" } }}
            onClick={handleClick}
        >
            <Icon name="down" value="left" className="left-arrow" />
            {mode === 'id' && <h4><strong>Casa</strong> anterior</h4>}
            {mode === 'page' && page > 1 && <h4><strong>Página</strong> anterior</h4>}
        </motion.div>
    )
}

const Right = () => {
    const dispatch = useDispatch();
    const { results: { data, page } } = useSelector(state => state.pagination);
    const { open: { id }, keys: { right } } = useSelector(state => state.ui);
    const [mode, setMode] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (data) {
            const lastPage = 10000;
            const lastImage = 9;
            if (!!id) {
                const id_index = data.findIndex(image => image._id && image._id === id);
                setIndex(id_index);
                if (id_index < lastImage) {
                    setMode('id');
                } else if (page < lastPage) {
                    setMode('page');
                }
            } else if (page < lastPage) {
                setMode('page');
            }
        }
    }, [data, page, id]);

    useEffect(() => {
        if (right) {
            dispatch(setUi(['animate_down_right', true]));
            handleClick();
            dispatch(setUi(['keys.right', false]));
        }
    }, [right])

    const handleClick = () => {
        if (mode === 'id') {
            dispatch(setUi(['open.id', data[index + 1]._id]));
            dispatch(setUi(['open.item', data[index + 1].item]));
        } else if (mode === 'page') {
            dispatch(incrementPage('results'));
            dispatch(setUi(['open.id', null]));
            dispatch(setUi(['open.item', null]));
        }
    }

    return (
        <motion.div
            className="right"
            variants={{
                "hidden": { opacity: 0 },
                "show": { opacity: 0.4 },
                "hover": { scale: 1.1 },
                "tap": { scale: 1.2 }
            }}
            initial="hidden"
            animate={mode ? "show" : "hidden"}
            whileHover="hover"
            whileTap="tap"
            transition={{ opacity: { ease: "easeIn" }, scale: { ease: "anticipate" } }}
            onClick={handleClick}
        >
            <Icon name="down" value="right" className="right-arrow" />
            {mode === 'id' && <h4>Próxima <strong>casa</strong></h4>}
            {mode === 'page' && <h4>Próxima <strong>página</strong></h4>}
        </motion.div>
    )
}

const Pagination = () => {
    const { results: { page } } = useSelector(state => state.pagination);
    const { selection: { results } } = useSelector(state => state.user);

    return (
        <div className="pagination">{page} / {results ? results.length / 10 : 0}</div>
    )
}

const ArrowControls = () => {
    return (
        <div className="arrow-controls">
            <Left />
            <Right />
            <Pagination />
        </div>
    )
}

export default ArrowControls;