import { useState } from "react";
import { useSelector } from "react-redux";

import ContentLoader from 'react-content-loader'
import { Grid, Cards, CardBody, CardFooter } from "grommet";
import { AnimatePresence, motion } from "framer-motion";

import Icon from "./Icon";
import Button from "./Button";

import { useGetCollectionQuery } from "../store";

const Carousel = ({ url }) => {
    return (
        <div className="carousel">
            <AnimatePresence>
                <motion.img
                    key={url}
                    src={url}
                    initial={{ opacity: 0 }}
                    animate={{  opacity: 1 }}
                    exit={{opacity: 0 }}
                    transition={{ ease: "anticipate", duration: 0.3 }}
                />
            </AnimatePresence>
        </div>
    )
}

const CollectionCard = ({ item }) => {
    const [imageNo, setImageNo] = useState(0);

    return (
        <div className="card">
            <CardBody>
                <Carousel url={item.img[(imageNo % item.img.length)]} />
                <div className="image-controls"
                    onClick={() => setImageNo(imageNo + 1)}
                >
                    <motion.div
                        className="left"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 1.2 }}
                        transition={{ opacity: { ease: "easeIn" }, scale: { ease: "anticipate" } }}
                        onClick={() => setImageNo(imageNo - 1)}
                    >
                        <Icon name="down" value="left" colors="primary:#FAFAFA" />
                    </motion.div>
                    <motion.div
                        className="right"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 1.2 }}
                        transition={{ opacity: { ease: "easeIn" }, scale: { ease: "anticipate" } }}
                        onClick={() => setImageNo(imageNo + 1)}
                    >
                        <Icon name="down" value="right" colors="primary:#FAFAFA" />
                    </motion.div>
                </div>
                <div className="card-body">
                    <h3> {item['neighborhood']} </h3>
                    <div className="feature long">
                        <p>
                            {item['title']}
                        </p>
                    </div>
                    <div className="feature">
                        {item['bedrooms'] && item['bedrooms'] + " quartos"}
                    </div>
                    <div className="feature">
                        {item['parking'] && item['parking'] + " vagas de garagem"}
                    </div>
                    <div className="feature">
                        {item['built'] && item['built'].toLocaleString() + " m² de construção"}
                    </div>
                    <div className="feature">
                        {item['area'] && item['area'].toLocaleString() + " m² de terreno"}
                    </div>
                    <div className="price">
                        {"R$" + item.price.toLocaleString()}
                    </div>
                </div>
            </CardBody>
            <CardFooter className="cta">
                <Button label="Agendar visita" />
                <Button white label="Arquivar" />
            </CardFooter>
        </div>
    )
}

const CollectionDisplay = () => {
    const { username } = useSelector(state => state.user);
    const { data, isLoading, isFetching } = useGetCollectionQuery({ username });

    if (!username || isLoading || isFetching) {
        return (
            <div className="card-table">
                <ContentLoader
                    gradientDirection="top-bottom"
                    width="1340" height="563" viewBox="0 0 1340 563"
                    backgroundColor="rgb(0,0,0)"
                    foregroundColor="rgb(0,0,0)"
                    backgroundOpacity={0.06}
                    foregroundOpacity={0.12}
                >
                    <path d="M3 20C3 8.95431 11.9543 0 23 0H300C311.046 0 320 8.95431 320 20V200H3V20Z" />
                    <rect x="27" y="321" width="270" height="1" />
                    <rect x="27" y="383" width="270" height="1" />
                    <rect x="27" y="414" width="270" height="1" />
                    <rect x="27" y="352" width="270" height="1" />
                    <rect x="27" y="445" width="270" height="1" />
                    <rect x="148" y="507" width="150" height="40" rx="20" />
                    <path d="M343 20C343 8.95431 351.954 0 363 0H640C651.046 0 660 8.95431 660 20V200H343V20Z" />
                    <rect x="367" y="321" width="270" height="1" />
                    <rect x="367" y="383" width="270" height="1" />
                    <rect x="367" y="414" width="270" height="1" />
                    <rect x="367" y="352" width="270" height="1" />
                    <rect x="367" y="445" width="270" height="1" />
                    <rect x="488" y="507" width="150" height="40" rx="20" />
                    <path d="M683 20C683 8.95431 691.954 0 703 0H980C991.046 0 1000 8.95431 1000 20V200H683V20Z" />
                    <rect x="707" y="321" width="270" height="1" />
                    <rect x="707" y="383" width="270" height="1" />
                    <rect x="707" y="414" width="270" height="1" />
                    <rect x="707" y="352" width="270" height="1" />
                    <rect x="707" y="445" width="270" height="1" />
                    <rect x="828" y="507" width="150" height="40" rx="20" />
                    <path d="M1023 20C1023 8.95431 1031.95 0 1043 0H1320C1331.05 0 1340 8.95431 1340 20V200H1023V20Z" />
                    <rect x="1047" y="321" width="270" height="1" />
                    <rect x="1047" y="383" width="270" height="1" />
                    <rect x="1047" y="414" width="270" height="1" />
                    <rect x="1047" y="352" width="270" height="1" />
                    <rect x="1047" y="445" width="270" height="1" />
                    <rect x="1168" y="507" width="150" height="40" rx="20" />
                </ContentLoader>
            </div>
        )
    } else if (data) {
        return (
            <motion.div
                initial={{ scale: 0.8, opacity: 0.8 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ ease: "anticipate" }}
                className="card-table"
            >
                <div className="card-content">
                    <Grid
                        columns={{ count: 10, size: "auto" }}
                        rows={{ count: 1, size: "300px" }}
                    >
                        <div className="spacer"></div>
                        <Cards>
                            {(item, i) => (
                                <CollectionCard key={i} item={item} />
                            )}
                        </Cards>
                    </Grid>
                </div>
            </motion.div>
        )
    }

}

export default CollectionDisplay;