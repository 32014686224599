import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const resultsApi = createApi({
    reducerPath: "results",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            getItems: builder.query({
                query: (payload) => {
                    return {
                        url: `/api/results`,
                        method: 'POST',
                        body: payload
                    }
                },
                providesTags: ['items']
            }),
            getDetail: builder.query({
                query: (payload) => {
                    return {
                        url: `/api/detail`,
                        method: 'POST',
                        body: payload
                    }
                },
                providesTags: ['detail']
            }),
            getComments: builder.query({
                query: (item) => {
                    return {
                        url: `/api/comments/${item}`,
                        method: 'GET',
                    }
                },
                providesTags: ['comments']
            }),
            addComment: builder.mutation({
                query: (payload) => {
                    return {
                        url: `/api/comment`,
                        method: 'POST',
                        body: payload
                    }
                },
                invalidatesTags: ['comments']
            }),
            deleteComment: builder.mutation({
                query: (payload) => {
                    return {
                        url: `/api/comment`,
                        method: 'DELETE',
                        body: payload
                    }
                },
                invalidatesTags: ['comments']
            }),
            getImages: builder.query({
                query: (payload) => {
                    return {
                        url: `/api/images`,
                        method: 'POST',
                        body: payload
                    }
                },
                providesTags: ['images']
            }),
            getImage: builder.query({
                query: (id) => {
                    return {
                        url: `/api/image/${id}`,
                        method: 'GET'
                    }
                },
            }),
            getCount: builder.query({
                query: () => {
                    return {
                        url: `/api/count`,
                        method: 'GET',
                    }
                },
                providesTags: ['count']
            }),
        }
    }
});

export const {
    useGetItemsQuery,
    useGetDetailQuery,
    useGetImagesQuery,
    useGetImageQuery,
    useGetCountQuery,
    useGetCommentsQuery,
    useAddCommentMutation,
    useDeleteCommentMutation,
} = resultsApi;
export default resultsApi;