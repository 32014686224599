import React from "react";
import ReactGA from "react-ga4";
import Button from "../components/Button";
import { useSelector } from "react-redux";
import { useRememberDeviceQuery } from "../store";
import { useNavigate } from "react-router-dom";

const Discovery = () => {
    const { deviceId } = useSelector(state => state.ui);
    const { isLoading, isFetching } = useRememberDeviceQuery(deviceId);
    const navigate = useNavigate();
    const handleSubmit = () => {
        ReactGA.event({ category: "Engagement", action: "Start", label: "Descobrir imóveis", value: 1 });
        navigate('/quanto-investir');
    }

    return (
        <div className="questionaire">
            <div className="content">
                <div className="title-subtitle">
                    <h2 className="fix-height-3">
                        Sua casa dos sonhos<br /> pode estar à venda<br /> neste <strong>momento</strong>...
                    </h2>
                    <p>Descubra casas inspiradas em você à venda nas principais imobiliárias de São Paulo <br /><b>em menos de 3 minutos</b>.</p>
                </div>
                <div className="spacer"></div>
                <div className="buttons">
                    <Button primary disabled={isLoading || isFetching} label="Descobrir imóveis" icon="arrow" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default Discovery;