import { useSelector } from "react-redux";
import Controls from "./Controls";

const DevTools = () => {
    const { ip } = useSelector(state => state.user);
    
    if (ip === "201.69.55.112") {
        return (
            <div
                style={{
                    position: 'fixed',
                    left: '0',
                    bottom: '0',
                    zIndex: 100000,
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: true,
                    gap: '6px'
                }}
            >
                <Controls />
            </div>
        )
    }
}

export default DevTools;