import { motion } from "framer-motion";
import Logo from "../components/Logo";
import UserMenu from "../components/UserMenu";
import Footer from "../components/Footer";
import NotificationCenter from "../components/Notification";
import { components, screens_default_config } from "./";
import ModalCenter from "../tools/ModalCenter";
import { useSelector } from "react-redux";
import StatusBar from "../components/StatusBar";

const Layout = ({ screen }) => {
    const { animate } = useSelector(state => state.ui);
    const { layout, menu, userMenu, background, innerMain } = screens_default_config[screen];
    const { Main, Display, Navigation, Bottom } = components[screen];

    return (
        <motion.div className={"app layout" + layout + " " + background + " " + screen} {...animate}>
            <div className="header" >
                <motion.div className="left-menu" {...animate}>
                    <Logo />
                </motion.div>
                <div className="menu">
                    {menu && <StatusBar />}
                </div>
                <div className="right-menu">
                    {userMenu && <UserMenu />}
                </div>
            </div>
            <motion.div className="display" {...animate} >
                <Display />
            </motion.div>
            <motion.div className="main" {...animate} >
                <motion.div className={innerMain ? "inner-main" : "no-inner-main"} {...animate} >
                    {(screen !== "collection") && (screen !== "references") &&
                        <div className="step-wizard">
                            <Navigation />
                        </div>}
                    <Main />
                    <div className="bottom">
                        <small>
                            <Bottom />
                        </small>
                    </div>
                </motion.div>
            </motion.div>
            <div className="footer" >
                <Footer />
            </div>
            <ModalCenter />
            <NotificationCenter />
        </motion.div >
    )
}

export default Layout;