export const theme = {
  name: "BeHaus",
  rounding: "var(--border-radius)",
  spacing: 24,
  defaultMode: "light",
  global: {
    colors: {
      brand: "var(--brand)",
      focus: "var(--focus)",
      dark: "var(--dark-background)",
      placeholder: "var(--weak)",
      border: "var(--weaker)"
    },
    font: {
      family: '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      size: "16px",
      height: "18px",
      maxWidth: "498px"
    },
    drop: {
      border: {
        radius: "var(--border-radius)",
      }
    },
    control: {
      border: {
        radius: "var(--border-radius)",
        width: "2px"
      }
    },
    borderSize: 0,
    input: {
      font: {
        weight: 400
      }
    }
  },
  formField: {
    content: {
      margin: 0,
      pad: "medium"
    }
  },
  select: {
    icons: {
      color: "border"
    },
    options: {
      text: {
        size: "16px"
      }
    }
  }
}
