import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import { Grommet } from 'grommet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import FixMobileScroll from "./utils/FixMobileScroll";
import DevTools from "./dev/DevTools";

import "./styles.css";

import { useDispatch, useSelector } from 'react-redux';
import { landing_paths, screens_default_config } from './screens';
import { setUi } from './store';

import { theme } from "./theme";
import Layout from './screens/Layout';
import Explorer from './screens/Explorer';
import TheWorld from './dev/TheWorld';
import Tracker from './utils/tracker';

ReactGA.initialize("G-NCFSY3W2HR");

const App = () => {
    const { screen } = useSelector(state => state.ui);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setUi(['screen.config', screens_default_config['discovery']]))
    }, [])

    useEffect(() => {
        if (screen.name) {
            dispatch(setUi(['screen.config', screens_default_config[screen.name]]))
        }
    }, [screen])

    return (
        <Grommet full theme={theme}>
            <FixMobileScroll />
            <Tracker />
            <DevTools />
            <BrowserRouter>
                <Routes>
                    <Route path="/the-world-of-behaus" element={<TheWorld />} />
                    <Route path="/explorar" element={<Explorer />} />
                    {Object.keys(landing_paths).map((screen, i) => <Route key={i} path={landing_paths[screen]} element={<Layout screen={screen} />} />)}
                </Routes>
            </BrowserRouter>
        </Grommet>
    )
}

export default App;
