import Icon from "./Icon";
import close from "../img/icons/carret-down.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { removeNotification, setUi } from "../store";
import { Link } from "react-router-dom";

const NotificationCenter = () => {
    const state = useSelector(state => state.ui);
    const transition = state.transition;

    return (
        <motion.div
            layout
            transition={transition}

            className="notification-wrap"
        >
            <AnimatePresence mode="wait">
                {state.notifications.map(({ id, message, type }, i) => <Notification key={i} id={id} message={message} type={type} />)}
            </AnimatePresence>

        </motion.div>
    )
}

const Notification = ({ id, message, type = "info" }) => {
    const dispatch = useDispatch();
    const icon = type === "success" ? "check" :
        type === "warning" ? "info" :
            type === "critical" ? "warning" : type

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(removeNotification(id));
        }, 3000);
        return () => clearTimeout(timer);
    }, [])

    if (id) {
        return (
            <motion.div
                className={"notification " + type}

                variants={{
                    hidden: {
                        y: 100,
                        opacity: 1,
                    },
                    show: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            delayChildren: 0.5,
                            ease: "easeInOut"
                        }
                    }
                }}
                layout="position"
                initial="hidden"
                animate="show"
                exit="hidden"
            >
                <div className="left">
                    <Icon name={icon} colors="primary:#111111" />
                </div>
                <div className="message">
                    {message}
                </div>
                <div className="right">
                    <Link onClick={() => dispatch(removeNotification(id))} onDoubleClick={() => dispatch(setUi(['notifications', []]))}>
                        <img src={close} alt="fechar" />
                    </Link>
                </div>
            </motion.div>
        )
    }
}

export default NotificationCenter;