import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        loading: false,
        screen: {
            name: "discovery",
            config: {},
            last: null,
        },
        notifications: [],
        modal: null,
        open: {
            id: null,
            item: null,
            image_no: 0
        },
        keys: {
            up: null,
            down: null,
            left: null,
            right: null,
        },
        admin: {
            city: "São Paulo",
            skip: 0,
            bucket: 2,
            references: 10,
            similars: 10
        }
    },
    reducers: {
        setUi(state, action) {
            const keys = action.payload[0].split('.');

            if (keys.length === 1) {
                state[action.payload[0]] = action.payload[1];
            } else {
                let obj = state;

                for (let i = 0; i < keys.length - 1; i++) {
                    if (!obj.hasOwnProperty(keys[i])) {
                        obj[keys[i]] = {};
                    }
                    obj = obj[keys[i]];
                }

                obj[keys[keys.length - 1]] = action.payload[1];
            }
        },
        addNotification(state, action) {
            state.notifications.push({ id: new Date().getTime(), ...action.payload });
        },
        removeNotification(state, action) {
            state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
        },
    }
})

export default uiSlice.reducer;
export const {
    setUi, addNotification, removeNotification
} = uiSlice.actions;