import { useDispatch, useSelector } from "react-redux"
import { addNotification, setUi, useInvalidatesDeviceMutation, useRememberDeviceQuery } from "../store";
import Modal from "../components/Modal";
import Icon from "../components/Icon";
import Button from "../components/Button";
import { screens_default_config } from "../screens";
import { useState } from "react";

const ModalTemplate = ({ size }) => {
    const dispatch = useDispatch();

    return (
        <>
            <button onClick={() => dispatch(setUi(['open_modal_' + size, true]))}>modal {size.charAt(0)}</button>
            <Modal name={size} size={size} blur="simple">
                <Icon name="confetti" id="modal_icon" />
                <h3>Parabéns!</h3>
                <p>Você desbloqueou o próximo nível</p>
                <Button label="Ver recompensa" />
            </Modal>
        </>
    )
}

const Controls = () => {
    const { loading, screen } = useSelector(state => state.ui);
    const { deviceId, username } = useSelector(state => state.user);
    const { data, isFetching, isLoading } = useRememberDeviceQuery(deviceId);
    const [invalidatesDevice] = useInvalidatesDeviceMutation();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const screens = Object.keys(screens_default_config)

    return (
        <>
        <h3 className="white">{username}</h3>
            <h3 className="white">{screen.name}</h3>
            <button className="white" onClick={() => invalidatesDevice(deviceId)}>
                {isFetching ? "fetching" :
                    isLoading ? "loading" :
                        data ? "contains data" :
                            "no data"
                }
            </button>
            <button onClick={() => setShow(!show)}>{show ? "hide" : "show"}</button>
            {show &&
                <>
                    <button onClick={() => { localStorage.clear(); window.location.reload(); }}>clear</button>
                    <button onClick={() => dispatch(setUi(['loading', !loading]))} style={loading ? { fontWeight: 600 } : { fontWeight: 400 }}>loading</button>
                    <button onClick={() => dispatch(setUi(['screen.name', 'handpick']))}>handpick</button>
                    {screens.map((name, i) => <button key={i} onClick={() => dispatch(setUi(['screen.name', name]))}>{name}</button>)}
                    <ModalTemplate size="small" />
                    <ModalTemplate size="medium" />
                    <ModalTemplate size="big" />
                    <button onClick={() => dispatch(addNotification({ message: "Imóvel adicionado à sua lista", type: "success" }))} >success</button>
                    <button onClick={() => dispatch(addNotification({ message: "Imóvel adicionado à sua lista", type: "info" }))} >info</button>
                    <button onClick={() => dispatch(addNotification({ message: "Imóvel adicionado à sua lista", type: "warning" }))} >warning</button>
                    <button onClick={() => dispatch(addNotification({ message: "Imóvel adicionado à sua lista", type: "critical" }))} >critical</button>
                </>}
        </>
    )
}

export default Controls;