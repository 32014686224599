import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const adminApi = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            getNodes: builder.query({
                query: (payload) => {
                    return {
                        url: `/api/admin/nodes`,
                        method: 'POST',
                        body: payload
                    }
                },
                providesTags: ['nodes']
            }),
        }
    }
});

export const {
    useGetNodesQuery
} = adminApi;
export default adminApi;